export function round2digits (n) {
  return Math.round(n * 100) / 100
}

export function toAmount (n) {
  if (!n) {
    return ''
  }
  n = parseFloat(n)
  if (!n || n < 900) {
    return round2digits(n).toString()
  }
  n /= 1000
  if (n < 900) {
    return round2digits(n) + 'K'
  }
  n /= 1000
  if (n < 900) {
    return round2digits(n) + 'M'
  }
  return round2digits(n / 1000) + 'B'
}

export function fromAmount (n) {
  n = n.replace(',', '.').toUpperCase()
  if (n.indexOf('K') > 0) {
    return parseFloat(n) * 1000
  }
  if (n.indexOf('M') > 0) {
    return parseFloat(n) * 1000000
  }
  if (n.indexOf('B') > 0) {
    return parseFloat(n) * 1000000000
  }
  return parseFloat(n)
}

export function toSymbol (n) {
  switch (n) {
    case 'EUR':
    case 'eur':
      return '€'
    case 'USD':
    case 'usd':
      return '$'
    case 'NZD':
    case 'nzd':
      return 'NZ$'
  }
  return n || ''
}
