<template>
  <div>
    <h2 class="h2">Analytics cards</h2>
    <form-group label="Analytics cards to display">
      <dropdown multiple v-model="form.viewAnalytics" :options="viewAnalyticsOptions"/>
    </form-group>

    <h2 class="h2">Currency</h2>
    <form-group label="Choose the currency to be used when displaying financial information">
      <checkbox label="EUR" type="radio" v-model="displayCurrency" val="eur"/>
      <checkbox label="USD" type="radio" v-model="displayCurrency" val="usd"/>
      <checkbox label="NZD" type="radio" v-model="displayCurrency" val="nzd"/>
    </form-group>

    <template v-if="$store.getters.isPublisherEnabled">
      <h2 class="h2">Onboarding & Actor Search</h2>
      <form-group label="Personal Home">
        <checkbox style="margin-top: 10px" :model-value="form.personalHomeDisabled"
                  @update:modelValue="form.personalHomeDisabled = $event" label="Disable personal home."/>
      </form-group>

      <form-group label="Fields">
        <checkbox style="margin-top: 10px" :model-value="form.departmentFunctionFieldsOptional"
                  @update:modelValue="form.departmentFunctionFieldsOptional = $event"
                  label="Make department and function fields optional."/>
      </form-group>

      <form-group label="Registration">
        <checkbox style="margin-top: 10px" :model-value="form.socialLoginDisabled"
                  @update:modelValue="form.socialLoginDisabled = $event" label="Disable logging via social media."/>
      </form-group>

      <form-group label="Actor create / search">
        <checkbox style="margin-top: 10px" :model-value="form.includeCompanyNumberInSearch"
                  @update:modelValue="form.includeCompanyNumberInSearch = $event"
                  label="Include company number on that actor create page and to be searched in the search box"/>
      </form-group>
    </template>

    <form-group v-if="isDeveloper" label="Default IntroJS config">
      <dropdown :model-value="form.introJsDefaultSelection" :options="introJsOptions"
                @update:modelValue="form.introJsDefaultSelection = $event" multiple></dropdown>
    </form-group>
    <form-group label="For which users should the tutorial be automatically be displayed (once)">
      <radio-button
          name="enableForUsers"
          :model-value="form.introJsAutoTriggeredUsers"
          @update:modelValue="form.introJsAutoTriggeredUsers = $event"
          inline
          label="All users that haven't seen it yet created after"
          val="new"
      />
      <datepicker v-if="form.introJsAutoTriggeredUsers === 'new'" style="display: inline; width: 100px"
                  :model-value="form.introJsNewUserStartDate"
                  @update:modelValue="form.introJsNewUserStartDate = $event"/>
      <radio-button
          name="enableForUsers"
          :model-value="form.introJsAutoTriggeredUsers"
          @update:modelValue="form.introJsAutoTriggeredUsers = $event"
          label="All users that haven't seen it yet"
          val="all"
      />
    </form-group>
    <form-group v-if="isDeveloper" label="Custom IntroJs configuration (this overrides all default settings)">
      <ds-textarea :model-value="form.introJsCustomConfig"
                   @update:modelValue="form.introJsCustomConfig = $event"></ds-textarea>
    </form-group>

    <div v-if="false && isDeveloper">
      <h2 class="h2">Monitoring Settings</h2>
      <form-group label="Actor Scouting">
        <checkbox style="margin-top: 10px" :model-value="form.enableActorScouting"
                  @update:modelValue="form.enableActorScouting = $event"
                  label="Enable Actor Scouting"/>
      </form-group>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../Form/Checkbox.vue'
import Dropdown from '../../Dropdown/Dropdown.vue'

import { viewAnalyticsOptions, viewListOptions } from '../../../constants/config'
import DsTextarea from '../../Form/DsTextarea.vue'
import IntroJsMixin, { defaultAllowedIntros, introTypes } from '../../../util/IntroJsMixin.js'
import RadioButton from '../../Form/RadioButton.vue'
import Datepicker from '../../Form/Datepicker.vue'

export default {
  name: 'Other',
  props: ['form'],
  data () {
    return {
      viewAnalyticsOptions,
      viewListOptions,
      defaultAllowedIntros: defaultAllowedIntros.map(intro => {
        return { value: intro, label: this.getNameForIntroValue(intro) }
      }),
    }
  },
  computed: {
    displayCurrency: {
      set (value) {
        if (!this.form.displayCurrency) {
          this.form.displayCurrency = 'eur'
        }

        this.form.displayCurrency = value
      },
      get () {
        return this.form.displayCurrency || 'eur'
      },
    },
    isExplorationOnly () {
      return this.$store.getters.isExplorationOnly
    },
    introJsOptions () {
      if (this.isExplorationOnly) {
        return this.defaultAllowedIntros.filter(filter => filter.value.indexOf('exploration') >= 0)
      }

      return this.defaultAllowedIntros
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
  },
  methods: {
    getNameForIntroValue (value) {
      switch (value) {
        case introTypes.default_settings_configuration_exploration_owner_and_member:
          return 'Default settings intro Owner (Exploration)'
        case introTypes.default_settings_configuration_monitoring_owner_and_member:
          return 'Default settings intro Owner (Monitoring)'
        case introTypes.default_homepage_configuration_monitoring_owner_and_member:
          return 'Default homepage intro Owner (Monitoring)'
        case introTypes.default_homepage_configuration_monitoring_portfolio_member_and_actor:
          return 'Default homepage intro Ecosystem member, team member, portfolio member (Monitoring)'
        case introTypes.default_homepage_configuration_exploration_owner_and_member:
          return 'Default homepage intro Owner (Exploration)'
        case introTypes.default_concept_map_configuration_exploration:
          return 'Default concept map configuration (Exploration)'
        case introTypes.default_explore_content_configuration_exploration:
          return 'Default explore content configuration (Exploration)'
        case introTypes.default_personal_home_configuration_monitoring_all_users:
          return 'Default personal home configuration (Monitoring)'
        case introTypes.default_actor_profile_configuration_monitoring_all_users:
          return 'Default actor profile configuration (Monitoring)'
        case introTypes.default_spotting_areas_configuration_monitoring_all_users:
          return 'Default spotting areas configuration (Monitoring)'
        case introTypes.default_spotting_areas_detailpage_configuration_monitoring_all_users:
          return 'Default spotting Areas Detailpage (Monitoring)'
        case introTypes.default_message_board_configuration_monitoring_all_users:
          return 'Default message board configuration (Monitoring)'
        case introTypes.default_communities_configuration_monitoring_all_users:
          return 'Default communities configuration (Monitoring)'
        case introTypes.default_communities_home_configuration_monitoring_all_users:
          return 'Default communities home configuration (Monitoring)'
        case introTypes.default_communities_detailpage_configuration_monitoring_all_users:
          return 'Default communities detailpage configuration (Monitoring)'
        case introTypes.default_monitoring_configuration_exploration_owner:
          return 'Default trend monitoring configuration (Exploration)'
        default:
          return 'unsupported value'
      }
    },
  },
  components: {
    Datepicker,
    RadioButton,
    Checkbox,
    Dropdown,
    DsTextarea,
  },
  mixins: [IntroJsMixin],
}
</script>

<style scoped>

</style>
