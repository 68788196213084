<template>
  <a v-if="urls && urls.length === 1" class="laicos-item" :href="urls[0]" target="_blank">
    <slot></slot>
  </a>
  <button v-else-if="urls && urls.length" class="laicos-item" type="button" @mousedown.capture="click" @touchstart.capture="click" @focus="open = true" @blur="blur">
    <slot></slot>
    <div v-if="open" class="social-dropdown" @mousedown.stop @touchstart.stop>
      <a v-for="item in urls" :href="item" target="_blank" @blur="blur">{{ shorten(item) }}</a>
    </div>
  </button>
</template>

<script>
export default {
  props: ['urls'],
  data () {
    return {
      open: false,
    }
  },
  methods: {
    shorten (url) {
      return url.replace(/^.*:\/\/.*\/(.*)/, '$1')
    },
    click (evt) {
      this.open = !this.open

      if (evt.target && evt.target.href) {
        window.open(evt.target.href, '_blank')
      }
    },
    blur () {
      setTimeout(() => {
        if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
          this.open = false
        }
      }, 0)
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.laicos-item {
  position: relative;
  border: none;
  background: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-primary;
    .fill-fg {
      fill: $color-primary;
    }
  }
  .fill-fg {
    fill: black;
  }
  &:hover .fill-fg {
    fill: var(primary-light);
  }
  &:hover .letters {
    fill: #FFFFFF;
  }
  >.svg-icon {
    display: block;
  }
}

.social-dropdown {
  position: absolute;
  z-index: 5;
  top: 100%;
  left: -2px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  >a {
    display: block;
    padding: .2em 1em;
    color: white;
    text-decoration: none;
    &:hover {
      background: rgba(255, 255, 255, .2);
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $color-primary;
    }
    &:first-child {
      padding-top: .6em;
    }
    &:last-child {
      padding-bottom: .6em;
    }
  }
}
</style>
