<template>
  <div class="row actor-detail-info">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <AsideSection :title="actor.actor_type == 'Person' ? 'Info' : 'Company info'">
        <table class="table table--minimal">
          <tbody>
          <tr v-for="row in companyInfo">
            <td>{{ row.term }}</td>
            <td class="pre-line" style="text-align: right;">{{ row.description }}</td>
          </tr>
          </tbody>
        </table>
      </AsideSection>

      <AsideSection title="Contact Info" v-if="companyHasContactInfo">
        <table class="table table--minimal">
          <tbody>
          <tr v-for="row in companyContactInfo">
            <td>{{ row.term }}</td>
            <td class="pre-line" style="text-align: right;">{{ row.description }}</td>
          </tr>
          </tbody>
        </table>
      </AsideSection>

      <AsideSection title="Segmentation" v-if="segmentation.length > 0">
        <table class="table table--minimal">
          <tbody>
          <tr v-for="row in segmentation">
            <td>{{ row.term }}</td>
            <td class="pre-line" style="text-align: right;">{{ row.description || spaced(row.descriptions) }}</td>
          </tr>
          </tbody>
        </table>
      </AsideSection>

      <AsideSection title="Portfolios" v-if="actor.portfolios && actor.portfolios.length > 0">
        <Badge
            v-for="portfolio in actor.portfolios" :key="portfolio.id" :name="portfolio.name" variant="primary"
            @click="setPorfolioFilter(portfolio)"
            class="actor-detail-info__portfolio-tags"
        />
      </AsideSection>
      <AsideSection
          :title="'Significant Changes (' + currentMonth +')'"
          v-if="currentMonthSignificantChanges.length > 0"
      >
        <div
            v-for="(significantChange, index) in currentMonthSignificantChanges.slice(0, maxAmountOfSigChangesInSideBar)"
            style="margin-bottom:1em"
        >
          <ActorDetailProfileMainContentBlock
              :accentColor="cardBorderColor" type="SIGNIFICANT_CHANGE"
              :data="significantChange"
          />
        </div>
      </AsideSection>
    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <NewCard
          :title="'Custom Score: ' + actor.custom_score" collapsable
          :isCollapsedOnInit="$store.getters.userSettings.collapsedCards && $store.getters.userSettings.collapsedCards.custom_score"
          @isCollapsed="setCardCollapseState($event, 'custom_score')"
          v-if="hasCustomScores && customScoreFeatureFlagEnabled"
      >
        <div class="row">
          <div class="col-sm-6">
            <dl class="card__info__custom-score" v-if="customScoreMeta">
              <template v-for="customScore in customScoreMeta">
                <dt class="actor-detail__custom-score"><span
                    class="actor-detail__custom-score-score"
                >+{{
                    customScore.meta.calculatedScore || '0'
                  }}</span> - {{
                    humanize(customScore.parameter)
                  }} <span class="actor-detail__custom-score-weight">({{
                      customScore.meta.weight
                    }}%)</span></dt>
                <dd
                    style="font-size: 13px; font-weight: 500;"
                    v-for="(entry, key) in customScore.meta.parameters"
                >
                  <template v-if="typeof entry == 'string'">
                    {{ humanizeMetricValue(customScore.parameter, entry) }}
                    <!--- ({{ actor.custom_score_meta[parameter].score }})-->
                  </template>
                  <template
                      v-else-if="typeof entry == 'object' || typeof entry == 'array'"
                  >
                    <template v-if="entry.url">
                      <a :href="entry.url" target="_blank">{{ key }}</a>
                    </template>
                    <template v-else>
                      {{ key }}
                      <!--- {{ toDateString(entry.date) }} - ({{ entry.score }})-->
                    </template>
                  </template>
                </dd>
              </template>
            </dl>
          </div>

          <div class="col-sm-6">
            <template v-if="doesGraphHaveActualData(customScoresChartData)">
              <Metric label="Custom Score Evolution" orientation="horizontal"/>
              <SimpleLineChart :data="customScoresChartData"/>
            </template>
          </div>
        </div>
      </NewCard>

      <NewCard
          title="Employees & Financials" collapsable :isCollapsedOnInit="collapseEmployeesAndRevenue"
          @isCollapsed="setCardCollapseState($event, 'employees_revenue')"
          v-if="displayEmployeesAndRevenue"
      >
        <div class="row">
          <div class="col-sm-6">
            <Metric label="Employees:" :model-value="currentEmployees" orientation="horizontal"/>
            <br>
            <template v-if="doesGraphHaveActualData(employeesChartData)">
              <SimpleLineChart :data="employeesChartData"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
          <div class="col-sm-6">
            <Metric
                :label="operatingIncomeEstimated ? 'Estimated Revenue:' : 'Revenue:'"
                :model-value="currentFinancialValue('operating_income')" orientation="horizontal"
            />
            <br>
            <template v-if="doesGraphHaveActualData(financialChartData('operating_income'))">
              <SimpleLineChart :data="financialChartData('operating_income')" :moneyFormatted="true"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
        </div>

        <div
            class="row"
            v-if="doesGraphHaveActualData(financialChartData('equity')) || doesGraphHaveActualData(financialChartData('ebitda'))"
        >
          <div class="col-sm-6">
            <Metric label="Equity:" :model-value="currentFinancialValue('equity')" orientation="horizontal"/>
            <br>
            <template v-if="doesGraphHaveActualData(financialChartData('equity'))">
              <SimpleLineChart :data="financialChartData('equity')" :moneyFormatted="true"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
          <div class="col-sm-6">
            <Metric label="EBITDA:" :model-value="currentFinancialValue('ebitda')" orientation="horizontal"/>
            <br>
            <template v-if="doesGraphHaveActualData(financialChartData('ebitda'))">
              <SimpleLineChart :data="financialChartData('ebitda')" :moneyFormatted="true"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
        </div>
      </NewCard>

      <NewCard
          :title="innovationScoreTitle" collapsable
          :isCollapsedOnInit="$store.getters.userSettings.collapsedCards && $store.getters.userSettings.collapsedCards.innovation"
          @isCollapsed="setCardCollapseState($event, 'innovation')"
          :fillAvailableHeight="false"
          v-if="displayInnovation"
      >
        <div class="row" v-if="actor.innovation_score && actor.innovation_score.total && hasAccessToScores">
          <div class="col-sm-6">
            <dl class="card__info__custom-score" v-if="innovationScoreMeta">
              <template v-for="customScore in innovationScoreMeta">
                <dt class="actor-detail__custom-score"><span
                    class="actor-detail__custom-score-score"
                >+{{
                    customScore.meta.calculatedScore || '0'
                  }}</span> - {{
                    humanize(customScore.parameter)
                  }}
                </dt>
                <dd
                    style="font-size: 13px; font-weight: 500;"
                    v-for="(key) in customScore.meta.parameters"
                >
                  {{ humanize(key) }}
                </dd>
              </template>
            </dl>
          </div>
          <div class="col-sm-6">
            <template v-if="doesGraphHaveActualData(innovationScoresChartData)">
              <Metric label="Innovation Score Evolution" orientation="horizontal"/>
              <SimpleLineChart :data="innovationScoresChartData"/>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <Metric label="Patent publications:" :model-value="patentCount" orientation="horizontal"/>
            <br>
            <template v-if="doesGraphHaveActualData(patentHistoryChartData)">
              <SimpleLineChart :data="patentHistoryChartData"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
          <div class="col-sm-6">
            <Metric
                label="Intangible Assets" :model-value="currentFinancialValue('intangible_assets')"
                orientation="horizontal"
            />
            <br>
            <template v-if="doesGraphHaveActualData(financialChartData('intangible_assets'))">
              <SimpleLineChart :data="financialChartData('intangible_assets')" :moneyFormatted="true"/>
            </template>
            <template v-else>
              <p class="text-center"><small>No data available.</small></p>
            </template>
          </div>
        </div>

        <div class="row" v-if="hasLatestPatents">
          <div class="col-sm-12">
            <table class="table">
              <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(patent, index) in latestPatents">
                <tr>
                  <td>
                    <strong>
                      <Date :date="patent.date"/>
                    </strong>
                  </td>
                  <td><a :href="patent.link" target="_blank"><span
                      v-tooltip.top-center="{content: tooltipPatentContent(patent), popperClass: ['large-tooltip']}"
                  >{{
                      patent.title
                    }}</span></a>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </NewCard>

      <NewCard
          title="Growth Scores" collapsable
          :isCollapsedOnInit="$store.getters.userSettings.collapsedCards && $store.getters.userSettings.collapsedCards.growth"
          @isCollapsed="setCardCollapseState($event, 'growth')"
          v-if="hasScores && scores && actor.actor_type !== 'Person'"
      >
        <template v-slot:headerRight>
          <router-link
              :to="'/actors/' + $route.params.id + '/scores'"
              @click="trackHeapEvent('actorDetail.info.clickGrowthHackingDashboardLink')"
          >
            <small style="margin-right: 1em;">See growth hacking dashboard</small>
          </router-link>
        </template>
        <div class="row">
          <div class="col-sm-3 flex">
            <Metric label="Digital footprint score" :model-value="scores.digitalFootprint.value"/>
          </div>
          <div class="col-sm-3 flex">
            <Metric label="Digital footprint growth" :model-value="scores.digitalFootprint.growth"/>
          </div>
          <div class="col-sm-3 flex">
            <Metric label="Business size score" :model-value="scores.businessSize.value"/>
          </div>
          <div class="col-sm-3 flex">
            <Metric label="Business size growth" :model-value="scores.businessSize.growth"/>
          </div>
        </div>
      </NewCard>

      <NewCard
          v-if="displayFunding"
          title="Funding"
          collapsable
          :isCollapsedOnInit="fundingRoundsLength == 0 && totalFunding == 0"
          @isCollapsed="setCardCollapseState($event, 'funding')"
      >
        <template v-slot:headerRight>
          <div v-if="isCrunchBased" class="content-block__header__side-image" :style="getCrunchbaseLogoStyle"></div>
        </template>
        <div class="row">
          <div class="col-sm-6">
            <Metric label="Number of rounds:" :model-value="fundingRoundsLength" orientation="horizontal"/>
          </div>
          <div class="col-sm-6">
            <Metric label="Total funding:" :model-value="totalFunding" orientation="horizontal"/>
          </div>
        </div>
        <br>
        <table class="table" v-if="fundingRounds.length > 0">
          <thead>
          <tr>
            <th style="min-width: 100px">Date</th>
            <th style="min-width: 100px">Amount</th>
            <th style="min-width: 100px">Round</th>
            <th style="min-width: 100px">Investors</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="round in fundingRounds">
            <tr v-if="!round.hidden || $store.getters.isDeveloper">
              <td><strong>
                <Date :date="round.started_at" format="MMMM YYYY"/>
              </strong></td>
              <td v-if="round.capital_raised > 0"><strong>
                <Currency :amount="round.capital_raised" format="0.0a"/>
                {{ round.currency ? round.currency.toUpperCase() : '' }}</strong></td>
              <td v-else><strong>-</strong></td>
              <td><strong>{{ round.type }}</strong></td>
              <td><strong>{{ getInvestorNames(round) }}</strong></td>
            </tr>
            <tr v-else>
              <td class="blurred_text"><strong>
                <Date :date="round.started_at" format="MMMM YYYY"/>
              </strong></td>
              <td class="blurred_text">
                <Currency :amount="round.default_capital" format="0a"/>
                {{ round.currency ? round.currency.toUpperCase() : '' }}
              </td>
              <td>{{ round.type }}</td>
              <td class="blurred_text"></td>
            </tr>
          </template>
          </tbody>
        </table>
      </NewCard>

      <Grants
          v-if="displayGrants"
          :actor="company"
          @agentsMode="openProjectsAgentPanel(company.id)"
          @updateGrants="updateGrants"
      />

      <NewCard
          title="Acquired" collapsable :isCollapsedOnInit="(! exitRound || ! exitRound.date)"
          @isCollapsed="setCardCollapseState($event, 'exit')" v-if="displayExit"
      >
        <template v-slot:headerRight>
          <!--<div v-if="isCrunchBased" class="content-block__header__side-image" :style="getCrunchbaseLogoStyle"></div>-->
        </template>
        <table class="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>By</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>
              <Date :date="exitRound.date" format="MMMM YYYY"/>
            </strong></td>
            <td v-if="exitRound.original_amount > 0"><strong>
              <Currency :amount="exitRound.original_amount" format="0.0a"/>
              {{ exitRound.currency }}</strong></td>
            <td v-else><strong>-</strong></td>
            <td><strong>{{ exitRound.type }}</strong></td>
            <td><strong>{{ getAcquirers() }}</strong></td>
          </tr>
          </tbody>
        </table>
      </NewCard>

      <NewCard
          :title="officeCount + ' offices'" :force-open="show.addOffice && userCanEdit" collapsable
          :isCollapsedOnInit="$store.getters.userSettings.collapsedCards && $store.getters.userSettings.collapsedCards.office"
          hasIgnoreSlot
          @isCollapsed="setCardCollapseState($event, 'office', 'offices')" v-if="actor.actor_type != 'Person'"
      >
        <template v-slot:headerRight>
          <ds-button
              :icon="show.addOffice ? 'trash' : 'plus'" size="extra-small" variant="outline"
              @click="showAddOffice(show.addOffice)" style="margin-right:20px"
          />
        </template>
        <div v-if="show.addOffice && userCanEdit">
          <office-input @saved="officeSaved" :model-value="{ address: {} }" editable="Add office" :main="actor.id"/>
        </div>
        <table v-else class="table actor-detail-info__offices-table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Info</th>
            <th>Office address</th>
            <th v-if="userCanEdit"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              :class="{ 'table__row--selected': actor.id === selectedOfficeId }"
              @click="setSelectedOffice(actor)"
          >
            <td>
              <router-link :to="'/actors/' + actor.id">
                <template v-if="actor.company_type != 'Subsidiary'">
                  <strong>{{ actor.name }} (HQ)</strong>
                </template>
                <template v-else>
                  <strong>{{ actor.name }}</strong>
                </template>
              </router-link>
            </td>
            <td>
              <small>
                {{ actor.email }}
                <br v-if="actor.email">
                {{ actor.phone }}
              </small>
            </td>
            <td>
              <small class="pre-line">{{ formatAddress(actor.address) }}</small>
            </td>
            <td v-if="userCanEdit"></td>
          </tr>
          <template v-if="actor.subsidiaries.length > 0">
            <tr
                :class="{ 'table__row--selected': office.id === selectedOfficeId }" @click="setSelectedOffice(office)"
                v-for="(office, index) in actor.subsidiaries"
            >
              <td>
                <router-link :to="'/actors/' + office.id">
                  <strong>{{ office.name }}</strong>
                </router-link>
              </td>
              <td>
                <small>
                  {{ office.email }}
                  <br v-if="office.email">
                  {{ office.phone }}
                </small>
              </td>
              <td>
                <small class="pre-line">{{ formatAddress(office.address) }}</small>
              </td>
              <td v-if="userCanEdit" style="text-align: center; vertical-align: middle">
                <ds-button
                    class="actor-detail-info_office-edit-btn" icon="edit" variant="outline" size="small"
                    @click="editingAddress = office" style="margin-right:5px"
                />
                <ds-button
                    class="actor-detail-info_office-delete-btn" icon="trash" variant="outline" size="small"
                    @click="showOfficeDeleteConfirmationModal(office, index)"
                    style="margin-right:5px"
                />
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <br>
        <div v-if="!show.addOffice" style="position: relative; height: 220px">
          <SimpleMap :location="selectedOfficeLocation" :legend="legendValue" :disableMouseWheelZoom="true"/>
        </div>
        <template v-slot:ignoreSlot v-if="canShowOffices">
          <div style="position: relative; height: 220px">
            <div v-if="show.addOffice && userCanEdit">
              <office-input @saved="officeSaved" :model-value="{ address: {} }" editable="Add office" :main="actor.id"/>
            </div>
            <template v-else>
              <SimpleMap :location="actor.location" :offices="actor.subsidiaries" :disableMouseWheelZoom="true"/>
            </template>
          </div>
        </template>
      </NewCard>

      <address-modal
          v-if="editingAddress" :office="editingAddress" @saved="editingAddress = null"
          @close="fetchDetail"
      />

      <NewCard
          title="Competition" collapsable
          :isCollapsedOnInit="$store.getters.userSettings.collapsedCards && $store.getters.userSettings.collapsedCards.competition"
          @isCollapsed="setCardCollapseState($event, 'competition')" v-if="hasScores && canCompare && isMember"
      >
        <div class="row">
          <div class="col-sm-3" v-for="competitionNumber in competitionNumbers">
            <Metric :label="competitionNumber.description" :model-value="competitionNumber.value" style="height: 100%">
              <div class="divider"/>
              <small>{{ competitionNumber.previousDescription }}</small>
            </Metric>
          </div>
        </div>
      </NewCard>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'
import MODAL_IDS from '../../constants/modal-ids'

import { addressLines } from '../../util/helpers'
import { humanize } from '../../constants/properties'
import { toAmount } from '../../util/currency'
import CompanyMixin from '../../util/CompanyMixin'
import { toDateString, toOnlyMonthString } from '../../util/date'

import Badge from '../../components/Badge/Badge.vue'
import AsideSection from '../../components/AsideSection/AsideSection.vue'
import AddressModal from '../../components/Modals/AddressModal.vue'
import NewCard from '../../components/NewCard/NewCard.vue'
import DefinitionList from '../../components/DefinitionList/DefinitionList.vue'
import Metric from '../../components/Metric/Metric.vue'
import SimpleMap from '../../components/SimpleMap/SimpleMap.vue'
import SimpleLineChart from '../../components/Chart/SimpleLineChart.vue'
import OfficeInput from '../../components/Form/OfficeInput.vue'
import ActorDetailProfileMainContentBlock from './ActorDetailProfileMainContentBlock.vue'
import Modal from '../../components/Modals/Modal.vue'
import Grants from './ActorDetailInfo/Grants.vue'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'

import { trackHeapEvent } from '../../util/analytics'

import { updateActor } from '../../api/actors'
import ConfigMixin from '../../util/ConfigMixin'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
import { capitalize } from 'vue'

export default {
  name: 'ActorDetailInfo',
  data () {
    return {
      selectedOfficeId: this.actor.id,
      selectedOfficeLocation: this.actor.location,
      show: {
        addOffice: false,
      },
      editingAddress: null,
      maxAmountOfSigChangesInSideBar: 5,
      cardBorderColor: '#f5a623',
      operatingIncomeEstimated: false,
    }
  },
  props: {
    actor: Object,
    mainContentData: Object,
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    hasAccessToScores () {
      return this.$store.getters.hasAccessToScores
    },
    innovationScoreTitle () {
      if (this.hasAccessToScores && this.actor && this.actor.innovation_score && this.actor.innovation_score.total) {
        return 'Innovation Score: ' + (this.actor.innovation_score.total || 0)
      } else {
        return 'Innovation Score'
      }
    },
    portfolioActor () {
      return this.$store.getters.portfolioActor
    },
    hasLatestPatents () {
      return this.latestPatents.length > 0
    },
    latestPatents () {
      return (this.mainContentData && this.mainContentData.patents) || []
    },
    estimatedOperatingIncome () {
      // Return an array that holds the operating income of the actor
      // This can either be the data from the API or an estimation
      var hasOperatingIncome = false

      if (this.company.financials && this.company.financials.length > 0) {
        this.company.financials.forEach(financial => {
          if (financial['operating_income']) {
            hasOperatingIncome = true
          }
        })
      }

      if (hasOperatingIncome) {
        return this.company.financials
      }

      // We know we'll have data if we have employeesChartData, we're going to use the same approach for operating income
      if (this.employeesChartData &&
          this.employeesChartData.datasets &&
          this.employeesChartData.datasets[0] &&
          this.employeesChartData.datasets[0].data &&
          this.$store.getters.averageRevenuePerFTE &&
          !isNaN(this.$store.getters.averageRevenuePerFTE) &&
          this.employeesChartData.labels &&
          this.employeesChartData.labels.length > 0
      ) {
        var employees = this.employeesChartData.datasets[0].data
        var formattedDates = this.employeesChartData.labels

        var financials = []

        for (var index = 0; index < formattedDates.length; index++) {
          var date = moment(formattedDates[index], 'MMM [\']YY')
          var employeeCount = employees[index] || 0

          financials.push({
            end_period: date.format('YYYY-MM-DD'),
            operating_income: employeeCount * this.$store.getters.averageRevenuePerFTE,
          })
        }

        this.operatingIncomeEstimated = true

        return financials
      }

      return []
    },
    config () {
      return this.$store.state.config
    },
    customScoreFeatureFlagEnabled () {
      return this.$store.getters.isDeveloper || ['Satisco', 'Workero', 'salesenablement'].includes(this.$store.getters.ecosystemName)
    },
    company () {
      return this.$store.getters.detailActor
    },
    exitRound () {
      var exit = this.company.exit_round

      if (!exit) {
        return
      }

      return {
        amount: (toAmount(exit.original_capital_raised) || 0),
        original_amount: exit.original_capital_raised,
        start: moment(exit.started_at).format('MMM, YYYY'),
        date: moment(exit.started_at).format('MMM, YYYY'),
        currency: exit.original_currency,
        type: exit.type,
      }
    },
    companyContactInfo () {
      const actor = this.actor

      return [
        {
          term: 'Email',
          description: this.actor.email,
        },
        {
          term: 'Phone',
          description: this.actor.phone,
        },
      ].filter(entry => entry.description)
    },
    companyInfo () {
      const actor = this.actor

      var vatNumber = null
      var companyNumber = null

      if (actor.vat_number) {
        vatNumber = `${(actor.jurisdiction || '').toUpperCase()} ${actor.vat_number}`
      }

      if (actor.company_number) {
        companyNumber = `${(actor.jurisdiction || '').toUpperCase()} ${actor.company_number}`
      }

      // Get the section (nace 1), division (nace 2) and raw nace codes
      var sections = this.actorSections

      // Differ in the "founding date" term for persons vs non-persons
      var foundingDateTerm = 'Founding Date'

      if (actor.actor_type == 'Person') {
        foundingDateTerm = 'Born on'
      }

      var companyInfo = []

      /* if (this.$store.getters.hasCustomScores && actor.custom_score) {
        companyInfo.push({
          term: 'Custom Score',
          description: actor.custom_score
        })
      } */

      companyInfo = companyInfo.concat([
        {
          term: 'Name',
          description: this.actor.name,
        },
        {
          term: 'Juridical Form:',
          description: actor.legal_form,
        },
        {
          term: 'Company Number:',
          description: companyNumber,
        },
        {
          term: 'VAT Number:',
          description: vatNumber,
        },
        {
          term: 'Stock Symbol',
          description: actor.stock_symbol,
        },
        {
          term: foundingDateTerm + ':',
          description: actor.founding_date,
        },
        {
          term: 'Stopped at:',
          description: actor.stop_date,
        },
        {
          term: 'Address:',
          description: addressLines(actor.address),
        },
        {
          term: 'Province/State:',
          description: actor.address.admin1_name,
        },
        {
          term: 'Region:',
          description: actor.address.admin2_name,
        },
        {
          term: 'Sections:',
          description: this.actorSections && this.actorSections.length > 0 ? this.actorSections.join('\n') : null,
        },
        {
          term: 'Divisions:',
          description: this.actorDivisions && this.actorDivisions.length > 0 ? this.actorDivisions.join('\n') : null,
        },
        {
          term: 'NACE Codes:',
          description: this.actorNaces && this.actorNaces.length > 0 ? this.actorNaces.join('\n') : null,
        },
      ])

      return companyInfo.filter(entry => entry.description)
    },
    customScoreMeta () {
      if (!this.actor.custom_score_meta) {
        return
      }

      var meta = []

      for (var parameter in this.actor.custom_score_meta) {
        meta.push({
          parameter: parameter,
          meta: this.actor.custom_score_meta[parameter],
        })
      }

      meta.sort(function (a, b) {
        return b.meta['calculatedScore'] - a.meta['calculatedScore']
      })

      return meta
    },
    innovationScoreMeta () {
      if (!this.actor.innovation_score) {
        return
      }

      var meta = []

      for (var parameter in this.actor.innovation_score.meta) {
        meta.push({
          parameter: parameter,
          meta: this.actor.innovation_score.meta[parameter],
        })
      }

      meta.sort(function (a, b) {
        return b.meta['calculatedScore'] - a.meta['calculatedScore']
      })

      return meta
    },
    // Return the sections (NACE 1) where the actor is active in
    actorSections () {
      if (!this.actor.industry_codes || this.actor.industry_codes.length === 0) {
        return []
      }

      var sections = {}

      this.actor.industry_codes.forEach(function (code) {
        if (code.nace1 && code.nace1.length > 0 && code.nace1_description && code.nace1_description.length > 0) {
          sections[code.nace1] = code.nace1_description
        }
      })

      return Object.values(sections)
    },
    // Return the divisions (NACE 2) where the actor is active in
    actorDivisions () {
      if (!this.actor.industry_codes || this.actor.industry_codes.length === 0) {
        return []
      }

      var divisions = {}

      this.actor.industry_codes.forEach(function (code) {
        if (code.nace2 && code.nace2.length > 0 && code.nace2_description && code.nace2_description.length > 0) {
          divisions[code.nace2] = code.nace2 + ': ' + code.nace2_description.replace(';', ',')
        }
      })

      return Object.values(divisions)
    },
    // Return the full NACE (higher than NACE 2)
    actorNaces () {
      if (!this.actor.industry_codes || this.actor.industry_codes.length === 0) {
        return []
      }

      var naces = {}

      this.actor.industry_codes.forEach(function (code) {
        if (code.code && code.code.length > 2) {
          naces[code.code] = code.code + ': ' + code.description
        }
      })

      return Object.values(naces)
    },
    patentCount () {
      if (!this.actor.patent_count) {
        return 0
      }

      return this.actor.patent_count
    },
    segmentation () {
      const actor = this.actor

      var isSme = null

      if (actor.is_sme != undefined) {
        isSme = actor.is_sme ? 'Yes' : 'No'
      }

      var segmentation = [
        {
          term: 'Is SME:',
          description: isSme,
        },
        {
          term: 'Maturity:',
          description: actor.maturity,
        },
        {
          term: 'Funding Stage:',
          description: actor.funding_stage,
        },
        {
          term: this.getTaxonomyAlias('stage', false, 'Growth Stage') + ':',
          description: this.fetchSimpleSingleTaxonomyLabel(actor.stage),
        },
        {
          term: this.getTaxonomyAlias('funnel_stage', false, 'CRM Stage') + ':',
          description: this.fetchSimpleSingleTaxonomyLabel(actor.funnel_stage),
        },
        {
          term: this.getTaxonomyAlias('membership', false, 'Memberships') + ':',
          descriptions: this.fetchSimpleTaxonomyLabels(actor.membership),
        },
      ].filter(entry => entry.description || (entry.descriptions && entry.descriptions.length))

      return segmentation
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isActorClaimable () {
      // anno 2023: please use the userCanClaim check, as the claimable property should now be computed
      return true
      // return this.actor.claimable
    },
    isActorUnclaimed () {
      // the "claimed" computed property should be coming from the transformed actor object
      return !this.actor.claimed
    },
    isActorClaimed () {
      // the "claimed" computed property should be coming from the transformed actor object
      return this.actor && this.actor.claimed
    },
    isActorPublished () {
      return this.actor.published
    },
    isZombie () {
      return this.actor && this.actor.is_zombie
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isPortfolioMember () {
      return this.$store.getters.isPortfolioMember
    },
    canShowOffices () {
      return this.$store.state.ui.showOffices
    },
    collapsedCards () {
      return this.$store.state.ui.collapsedCards
    },
    scores () {
      if (!this.actor.scores) {
        return
      }

      const { digital_footprint, business_size } = this.actor.scores

      return {
        digitalFootprint: {
          value: this.formatScore(digital_footprint.value),
          growth: this.formatGrowth(digital_footprint.growth),
        },
        businessSize: {
          value: this.formatScore(business_size.value),
          growth: this.formatGrowth(business_size.growth),
        },
      }
    },
    hasScores () {
      return this.$store.getters.hasScores
    },
    hasCustomScores () {
      return this.$store.getters.hasCustomScores && this.actor.actor_type != 'Person' && this.actor.custom_score
    },
    canCompare () {
      return this.portfolioActor && this.portfolioActor.id && this.portfolioActor.id !== this.company.id
    },
    fundingRounds () {
      return (this.actor.funding_rounds || [])
          .slice()
          .sort((a, b) => (a.started_at || '').localeCompare(b.started_at || ''))
    },
    competitionNumbers () {
      let mentions = 0
      let lost = 0
      let won = 0
      let churned = 0

      let previousMentions = 0
      let previousLost = 0
      let previousWon = 0
      let previousChurned = 0

      if (this.actor.won_lost_mentions) {
        mentions = this.actor.won_lost_mentions.mentions || mentions
        lost = this.actor.won_lost_mentions.losses || lost
        won = this.actor.won_lost_mentions.wins || won
        churned = this.actor.won_lost_mentions.churned || churned
      }

      if (this.actor.previous_won_lost_mentions) {
        previousMentions = this.actor.previous_won_lost_mentions.mentions || previousMentions
        previousLost = this.actor.previous_won_lost_mentions.losses || previousLost
        previousWon = this.actor.previous_won_lost_mentions.wins || previousWon
        previousChurned = this.actor.won_lost_mentions.previousChurned || previousChurned
      }

      return [
        {
          value: mentions,
          description: 'Times ' + this.actor.name + ' has been mentioned in deals',
          previousDescription: this.actor.name + ' has been mentioned ' + previousMentions + ' in the previous month',
        },
        {
          value: lost,
          description: 'Deals lost to ' + this.actor.name,
          previousDescription: previousLost + ' deals were lost to ' + this.actor.name + ' in the previous month',
        },
        {
          value: won,
          description: 'Deals won from ' + this.actor.name,
          previousDescription: previousWon + ' deals were won from ' + this.actor.name + ' in the previous month',
        },
        {
          value: churned,
          description: 'Customers lost to ' + this.actor.name,
          previousDescription: previousChurned + ' customers were lost to ' + this.actor.name + ' in the previous month',
        },
      ]
    },
    employeesChartData () {
      if (!this.actor.employees) {
        return {
          labels: [],
          datasets: [],
        }
      }

      // Sort the data crescently based on the date
      this.actor.employees.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      const labels = this.actor.employees.map(entry => moment(entry.date).format('MMM [\']YY'))

      return {
        labels: labels,
        datasets: [{
          label: 'Employees',
          backgroundColor: this.$store.getters.primaryColor,
          borderColor: this.$store.getters.primaryColor,
          data: this.actor.employees.map(entry => (entry.lower_bound + entry.upper_bound) / 2),
          fill: false,
        }],
      }
    },
    customScoresChartData () {
      if (!this.actor.custom_score_overview) {
        return {
          labels: [],
          datasets: [],
        }
      }

      // Sort the data crescently based on the date
      this.actor.custom_score_overview.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      const labels = this.actor.custom_score_overview.map(entry => moment(entry.date).format('MMM [\']YY'))

      return {
        labels: labels,
        datasets: [{
          label: 'Custom Score',
          backgroundColor: this.$store.getters.primaryColor,
          borderColor: this.$store.getters.primaryColor,
          data: this.actor.custom_score_overview.map(entry => entry.value),
          fill: false,
        }],
      }
    },
    innovationScoresChartData () {
      if (!this.actor.innovation_score_evolution) {
        return {
          labels: [],
          datasets: [],
        }
      }

      // Sort the data crescently based on the date
      this.actor.innovation_score_evolution.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      const labels = this.actor.innovation_score_evolution.map(entry => moment(entry.date).format('MMM [\']YY'))

      return {
        labels: labels,
        datasets: [{
          label: 'Innovation Score',
          backgroundColor: this.$store.getters.primaryColor,
          borderColor: this.$store.getters.primaryColor,
          data: this.actor.innovation_score_evolution.map(entry => entry.value),
          fill: false,
        }],
      }
    },
    currentEmployees () {
      const currentEmployees = this.actor.current_employees

      if (currentEmployees && currentEmployees.length > 0) {
        return (currentEmployees[0].lower_bound + currentEmployees[0].upper_bound) / 2
      }

      return '-'
    },
    patentHistoryChartData () {
      if (!this.actor.patent_history) {
        return {}
      }

      var { patent_history } = this.actor

      // eslint-disable-next-line camelcase
      patent_history = patent_history.filter(entry => moment(entry.date).format('YYYY') < 9999)

      patent_history.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      const labels = patent_history.map(entry => moment(entry.date).format('YYYY'))

      return {
        labels: labels,
        datasets: [{
          label: 'Patent Count',
          backgroundColor: this.$store.getters.primaryColor,
          borderColor: this.$store.getters.primaryColor,
          data: patent_history.map(entry => entry.count || 0),
          fill: false,
        }],
      }
    },
    officeCount () {
      var counter = 0
      if (!this.actor.address || (this.actor.address && this.actor.address.length > 0)) {
        return counter
      }

      if (Object.keys(this.actor.address).length > 0) {
        counter += 1
      }
      if (this.actor.subsidiaries && this.actor.subsidiaries.length > 0) {
        counter += this.actor.subsidiaries.length
      }
      return counter
    },
    displayInnovation () {
      if (this.actor.actor_type == 'Person') {
        return false
      }

      return this.$store.getters.hasAccessToScores
    },
    displayEmployeesAndRevenue () {
      if (this.actor.actor_type == 'Person') {
        return false
      }

      // Only if the user has access to the employee/financials properties can the card be displayed
      if (this.isOwner) {
        return true
      }

      let publicFields = this.$store.getters.publicFields
      if (!this.isLoggedIn) {
        publicFields = this.$store.getters.publicFields
      } else {
        publicFields = this.$store.getters.publicFieldsForRole
      }

      if (this.$store.getters.isMember && publicFields.length == 0) {
        return true
      }

      if (this.hasUserClaimedActor) {
        return true
      }

      if (this.isPortfolioMember) {
        return true
      }

      return publicFields && publicFields.length > 0 && (publicFields.includes('financials') || publicFields.includes('employees'))
    },
    collapseEmployeesAndRevenue () {
      var isCollapsedInSettings = this.$store.getters.userSettings.collapsedCards && this.$store.getters.userSettings.collapsedCards.employees_revenue

      if (isCollapsedInSettings) {
        return true
      }

      return !this.doesGraphHaveActualData(this.financialChartData('operating_income')) &&
          !this.doesGraphHaveActualData(this.employeesChartData) &&
          !this.doesGraphHaveActualData(this.financialChartData('equity')) &&
          !this.doesGraphHaveActualData(this.financialChartData('ebitda'))
    },
    collapsePatents () {
      return this.$store.getters.userSettings.collapsedCards && this.$store.getters.userSettings.collapsedCards.innovation ||
          !this.doesGraphHaveActualData(this.patentHistoryChartData)
    },
    displayGrants () {
      if (this.company.actor_type == 'Person') {
        return false
      }

      // Only if the user has access to the funding properties can the card be displayed
      if (this.isOwner) {
        return true
      }

      const publicFields = this.$store.getters.publicFields

      if (this.$store.getters.isMember && publicFields.length == 0) {
        return true
      }

      if (this.hasUserClaimedActor) {
        return true
      }

      return publicFields && publicFields.length > 0 && (publicFields.includes('grants'))
    },
    displayFunding () {
      if (this.company.actor_type == 'Person') {
        return false
      }

      // Only if the user has access to the funding properties can the card be displayed
      if (this.isOwner) {
        return true
      }

      const publicFieldsForRole = this.$store.getters.publicFieldsForRole

      if (this.$store.getters.isMember && publicFieldsForRole.length == 0) {
        return true
      }

      if (this.hasUserClaimedActor) {
        return true
      }

      if (this.isPortfolioMember) {
        return true
      }

      return publicFieldsForRole && publicFieldsForRole.length > 0 && (publicFieldsForRole.includes('funding_rounds'))
    },
    displayExit () {
      if (this.company.actor_type == 'Person') {
        return false
      }

      if (!this.exitRound) {
        return false
      }

      // Only if the user has access to the exit properties can the card be displayed
      if (this.isOwner || this.isMember) {
        return true
      }

      const publicFields = this.$store.getters.publicFields

      if (this.$store.getters.isMember && publicFields.length == 0) {
        return true
      }

      if (this.hasUserClaimedActor) {
        return true
      }

      if (this.isPortfolioMember) {
        return true
      }

      return publicFields && publicFields.length > 0 && (publicFields.includes('exit_round'))
    },
    significantChanges () {
      return this.mainContentData.changes || []
    },
    currentMonthSignificantChanges () {
      // We filter the significant changes from this month
      return this.significantChanges.filter(item => {
        if (toOnlyMonthString(item.created_at) == this.currentMonth) {
          return item
        }
      })
    },
    currentMonth () {
      var currentMonth = new Date()
      return toOnlyMonthString(currentMonth)
    },
    totalFunding () {
      if (this.company.total_funding) {
        // return toAmount(this.company.total_funding, 5) + ' ' + this.company.total_funding_currency.toUpperCase()
        return numeral(this.company.total_funding).format('$0.00a')
      }

      return 'Undisclosed'
    },
    fundingRoundsLength () {
      return this.fundingRounds.length ? this.fundingRounds.length : 'Undisclosed'
    },
    isCrunchBased () {
      if (!this.fundingRounds) {
        return
      }

      var crunchbaseRounds = this.fundingRounds.filter(d => d.source == 'crunchbasepro')
      return crunchbaseRounds.length > 0
    },
    getCrunchbaseLogoStyle () {
      return `background-image: url('/images/powered_by_crunchbase.png')`
    },
    companyHasContactInfo () {
      return this.company.phone || this.company.email
    },
  },
  methods: {
    trackHeapEvent,
    formatAddress: addressLines,
    toDateString,
    humanize,
    viewPatents () {
      // View patents in the knowledge base
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_ACTOR, {
        facet: 'top_actor',
        value: this.actor.id,
        label: this.actor.name
      })
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_MEDIA_TYPE, 'patent')

      this.$router.push('/dashboards/knowledge-base')
    },
    humanizeMetricValue (metric, metricValue) {
      if (metric != 'employee_size') {
        return metricValue
      }

      if (metricValue == 'XS') {
        return metricValue + ' (1-10)'
      } else if (metricValue == 'S') {
        return metricValue + ' (11-50)'
      } else if (metricValue == 'M') {
        return metricValue + ' (51-100)'
      } else if (metricValue == 'L') {
        return metricValue + ' (101-500)'
      } else if (metricValue == 'XL') {
        return metricValue + ' (501-1000)'
      } else if (metricValue == 'XXL') {
        return metricValue + ' (1000+)'
      }

      return metricValue
    },
    formatGrowth (growth) {
      return `${numeral(growth * 100).format('0.00a')}` + '%'
    },
    formatScore (score) {
      return `${numeral(score).format('0.00a')}`
    },
    setSelectedOffice (office) {
      this.selectedOfficeLocation = office.location
      this.selectedOfficeId = office.id
    },
    doesGraphHaveActualData (graphData) {
      if (!graphData) {
        return false
      }

      return graphData && graphData.datasets && graphData.datasets[0] && graphData.datasets[0].data.length > 0 && graphData.datasets[0].data.some(entry => entry)
    },
    setCardCollapseState (value, type, cardType) {
      var userSettingsCollapsedCardsCopy = Object.assign({}, this.$store.getters.userSettings.collapsedCards, { [type]: value })
      this.$store.commit('USER/STORE_SETTINGS', { collapsedCards: userSettingsCollapsedCardsCopy })

      if (cardType && cardType == 'offices') {
        this.toggleOfficeVisibility()
      }
    },
    financialChartData (property) {
      var financials = this.actor.financials

      if (property === 'operating_income') {
        financials = this.estimatedOperatingIncome
      }

      if (!financials) {
        return {}
      }

      // Sort the data in an ascending way based on the end_period
      financials = financials.slice()

      financials.sort(function (a, b) {
        return new Date(a.end_period) - new Date(b.end_period)
      })

      const labels = financials.map(entry => moment(entry.end_period).format('MMM [\']YY'))

      let propertyLabel = property.replaceAll('_', ' ').toLowerCase()

      if (property === 'operating_income') {
        propertyLabel = 'Revenue'
      }

      return {
        labels: labels,
        datasets: [{
          label: capitalize(propertyLabel),
          backgroundColor: this.$store.getters.primaryColor,
          borderColor: this.$store.getters.primaryColor,
          data: financials.map(entry => entry[property] || 0),
          fill: false,
        }],
      }
    },
    currentFinancialValue (property) {
      if (!this.actor.financials) {
        return
      }

      var financials = this.actor.financials

      if (property === 'operating_income') {
        financials = this.estimatedOperatingIncome
      }

      if (!financials) {
        return
      }

      financials = financials.slice()

      // Make sure we sort them correctly
      financials.sort(function (a, b) {
        return new Date(a.end_period) - new Date(b.end_period)
      })

      if (financials && financials.length > 0) {
        const currentFinancial = financials[financials.length - 1]
        const currentValue = currentFinancial[property] || 0
        return numeral(currentValue).format('$0.00a')
      }
    },
    getAcquirers () {
      if (!this.actor.acquired_by || this.actor.acquired_by.length == 0) {
        return
      }

      return this.actor.acquired_by.map(acquirer => {
        return acquirer.to_name
      }).join(', ')
    },
    getInvestorNames (fundingRound) {
      if (!fundingRound.investors) {
        return ''
      }

      return fundingRound.investors.map(investor => investor.to_name).join(', ')
    },
    toggleOfficeVisibility () {
      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_OFFICES)
      this.show.addOffice = false
    },
    showAddOffice (state) {
      this.show.addOffice = !state
      trackHeapEvent('actorDetail.info.clickAddOffice')
    },
    officeSaved () {
      this.show.addOffice = false
    },
    redirecToDirectory () {
      this.$router.push('/actors/')
    },
    setPorfolioFilter (portfolio) {
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, portfolio.id)
      this.redirecToDirectory()

      trackHeapEvent('actorDetail.info.clickPortfolio')
    },
    fetchDetail () {
      this.editingAddress = null
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actor.id)
    },
    showOfficeDeleteConfirmationModal (data, index) {
      var modalContextData = Object.assign({ name: data.name, officeId: data.id, modalContextType: 'actor' })

      this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    updateActor (offices) {
      updateActor({
        id: this.actor.id,
        data: {
          subsidiaries: offices,
        },
      })
          .then(actor => {
            this.fetchDetail()
          })
    },
    deleteOffice (officeId) {
      // We fetch all of the offices that are not the selected one in order to update the actor
      var offices = this.actor.subsidiaries.filter(office => office.id !== officeId)
      this.updateActor(offices)
    },
    updateGrants (grants) {
      updateActor({
        id: this.actor.id,
        data: {
          grants: grants,
        },
      })
          .then(actor => {
            this.fetchDetail()
          })
    },
    tooltipPatentContent (patent) {
      var description = ''

      if (patent.abstract) {
        description += patent.abstract.replace(/(?:\r\n|\r|\n)/g, '<br>')
      }

      return description
    },
    spaced (list) {
      return list && list.length ? list.join(', ') : ''
    },
    openProjectsAgentPanel (actorId) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'agents',
        metaData: { id: actorId, agents: ['project_agent'] }
      })
    },
  },
  mounted () {
    this.$bus.on('actorDeleteConfirmation', (context) => {
      // This event will be thrown if we delete the actor (HQ) as well, don't act on it if that's the case
      if (context.id && context.id === this.actor.id) {
        return
      }

      this.deleteOffice(context.officeId)
    })

    // When loading we set the value to true so that in the map view we can display all of the offices
    if (this.canShowOffices == false) {
      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_OFFICES)
    }
  },
  beforeUnmount () {
    this.$bus.off('actorDeleteConfirmation')
  },
  watch: {
    actor (actor) {
      this.setSelectedOffice(actor)
      this.operatingIncomeEstimated = false
    },
  },
  mixins: [CompanyMixin, ConfigMixin],
  components: {
    AsideSection,
    AddressModal,
    Badge,
    DefinitionList,
    NewCard,
    Metric,
    SimpleMap,
    SimpleLineChart,
    OfficeInput,
    ActorDetailProfileMainContentBlock,
    Modal,
    Grants,
  },
}
</script>

<style lang="scss">

</style>
