<template>
  <div class="sdg-container">
    <div
         v-for="sdg in sortedSDGs"
         v-tooltip.top="translatedSustainabilityGoals[sdg - 1]"
         :class="!target.startsWith('simplified') && 'sdg-container-actor'">
      <img :src="sdgImagesPath + sdg + '.jpg'"
           :alt="translatedSustainabilityGoals[sdg - 1]"
           :class="{clickable : hasSdgFilters}"
           @click="showFiltersForSDG(sustainabilityGoals[sdg - 1])"
      />
    </div>
  </div>
</template>

<script>
  import { sustainabilityGoals, sustainabilityGoalsNL } from '../../constants/config.js'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'actor-detail-SDGs',
    data: () => {
      return {
        sustainabilityGoals,
        sustainabilityGoalsNL,
      }
    },
    props: {
      selectedGoals: { type: Array, default: () => [] },
      target: String,
    },
    computed: {
      sdgImagesPath () {
        return this.$store.getters.activeLocale === 'nl' ? '/images/SDG_nl/' : '/images/SDG/'
      },
      translatedSustainabilityGoals () {
        return this.$store.getters.activeLocale === 'nl' ? sustainabilityGoalsNL : sustainabilityGoals
      },
      config () {
        return this.$store.state.config
      },
      isProduct () {
        return this.actor && this.actor['actor_type'] === 'Product'
      },
      hasSdgFilters () {
        if (this.target === 'simplified-challenge') {
          return false
        }
        if (this.isSimplifiedTemplateUsed) {
          return true
        }
        const filterControlsToUse = this.isProduct ? this.config.filterControlsProducts : this.config.filterControls
        if (filterControlsToUse !== null) {
          return filterControlsToUse.includes('sustainability_goal')
        }
        return false
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      actor () {
        return this.$store.state.actors.detail.data
      },
      sortedSDGs () {
        return this.selectedGoals && this.selectedGoals.map(sdg => Number(sdg.substring(sdg.indexOf(' ') + 1, sdg.indexOf(':')))).sort((a, b) => a - b)
      },
    },
    methods: {
      showFiltersForSDG (sdg) {
        if (!this.hasSdgFilters) {
          return
        }
        console.log('filtering ion ', sdg)
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
          facet: 'sustainability_goal',
          values: [sdg],
        })

        if (this.isProduct) {
          if (this.isSimplifiedTemplateUsed) {
            this.$router.push('/products-simplified')
          } else {
            this.$router.push('/product-gallery')
          }
        } else {
          if (this.isSimplifiedTemplateUsed) {
            this.$router.push('/actors-simplified')
          } else {
            this.$router.push('/actor-gallery')
          }
        }
      },
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .sdg-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: -9px;
    max-height: 195px;
    overflow: hidden;
    max-width: 558px;

    img {
      margin-top: 12px;
      margin-right: 12px;
      width: 50px;
      height: 50px;
      pointer-events: all;

      &.clickable {
        cursor: pointer;
      }
    }

    &.sdg-container-actor {
      margin: 0 0 -8px 0;
      justify-content: initial;
      max-height: none;
      overflow: visible;
      max-width: none;

      img {
        width: calc(20% - 12px);
        height: calc(20% - 12px);
        margin-top: 12px;
        margin-right: 12px;
      }
    }
  }

  @media (max-width: $screen-lg) {
    .sdg-container {
      max-width: 550px;
      max-height: 65px;

      img {
      }

      &.sdg-container-actor {
        img {
          margin-top: 15px;
          margin-right: 15px;

          &:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (max-width: $screen-md) {
    .sdg-container {
      max-width: calc(100vw - 160px);
    }
  }

  @media (min-width: $screen-lg) and (max-width: $screen-xl) {
    .sdg-container {
      img {
        margin-top: 12px;
        margin-right: 12px;
      }

      &.sdg-container-actor {
        img {
          width: calc(25% - 9px);
          height: calc(25% - 9px);

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (min-width: $screen-xl) {
    .sdg-container {
      img {
        margin-top: 12px;
        margin-right: 12px;
      }

      &.sdg-container-actor {
        img {
          width: calc(16.66% - 10px);
          height: calc(16.66% - 10px);

          &:nth-child(6n) {
            margin-right: 0;
          }
        }
      }
    }
  }
</style>
