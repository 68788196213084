<template>
  <with-configuration-tabs>
    <div class="taxonomies fixed-heading">
      <div class="heading">
        <h1>Taxonomies</h1>
        <p class="guide_text">Manage classifications by filling out the following lists.
          <span v-if="isOwner">You can configure different display names for some taxonomies
            <router-link :to="'/settings/ecosystem#advanced'">in the settings.</router-link></span>
          &nbsp;Click <a :href="'/api/taxonomies/download'" target="_blank">here</a> to download a CSV version of your taxonomy.
        </p>
        <editor-locale-dropdown v-model="editingLocale"
          :missing-for-locale="missingForLocale"/>
        <p class="form-group__error" v-if="errorMessage">{{ errorMessage }}</p>
      </div>

      <div class="scrollable has-padding has-action-bar">
        <taxonomies-editor
          :taxonomies="taxonomies"
          :locale="editingLocale"
          @rename="handleRename"
          @move="handleMove"
          @add="handleAdd"
          @delete="handleDelete"
        />
      </div>
      <action-bar :editing="isEditing || isSaving">
        <ds-button variant="primary" :label="isSaving ? 'Saving...' : 'Save changes'"
          @click="save" :disabled="isSaving"/>
        <ds-button variant="" label="Cancel" @click="cancel"/>
        <span class="action-bar__message" v-if="success">{{ success }}</span>
      </action-bar>

      <modal title="An error occurred"
        v-if="show.errors"
        close-on-blur
        closeable
        @close="show.errors = false">
        <template v-slot:body>
          <div>
            <div v-if="failed.categories.length">
              {{ failed.categories.length }} categories were not deleted because they are
              still in use.
              <div class="failed-item" v-for="category in failed.categories">
                {{ category.actors.length }} actors with category <b>{{ category.name }}</b>
                <div v-for="actor in category.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
            <div v-if="failed.domains.length">
              {{ failed.domains.length }} domains were not deleted because they are still in
              use.
              <div class="failed-item" v-for="domain in failed.domains">
                {{ domain.actors.length }} actors in domain <b>{{ domain.name }}</b>
                <div v-for="actor in domain.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
            <div v-if="failed.industries.length">
              {{ failed.industries.length }} industries were not deleted because they are
              still in use.
              <div class="failed-item" v-for="industry in failed.industries">
                {{ industry.actors.length }} actors in industry <b>{{ industry.name }}</b>
                <div v-for="actor in industry.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
            <div v-if="failed.productFeaturesA.length">
              {{ failed.productFeaturesA.length }} product features A were not deleted
              because they are still in use.
              <div class="failed-item" v-for="productFeatureA in failed.productFeaturesA">
                {{ productFeatureA.actors.length }} actors in Product Feature A
                <b>{{ productFeatureA.name }}</b>
                <div v-for="actor in productFeatureA.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
            <div v-if="failed.productFeaturesB.length">
              {{ failed.productFeaturesB.length }} product features B were not deleted
              because they are still in use.
              <div class="failed-item" v-for="productFeatureB in failed.productFeaturesB">
                {{ productFeatureB.actors.length }} actors in Product Feature B
                <b>{{ productFeatureB.name }}</b>
                <div v-for="actor in productFeatureB.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
            <div v-if="failed.productFeaturesC.length">
              {{ failed.productFeaturesC.length }} product features C were not deleted
              because they are still in use.
              <div class="failed-item" v-for="productFeatureC in failed.productFeaturesC">
                {{ productFeatureC.actors.length }} actors in Product Feature C
                <b>{{ productFeatureC.name }}</b>
                <div v-for="actor in productFeatureC.actors">
                  <a :href="'/actors/' + actor.id">{{ actor.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </div>
  </with-configuration-tabs>
</template>

<script>
  import {
    Activities,
    AnnouncementCategories,
    Aspects,
    BusinessAspects,
    Categories,
    Domains,
    EventCategories,
    Expertises,
    FunnelStages,
    Industries,
    Memberships,
    Motivations,
    ProcessSteps,
    ProductFeaturesA,
    ProductFeaturesB,
    ProductFeaturesC,
    ReadinessLevels,
    Stages,
    Technologies,
  } from '../../api/taxonomies'

  import ActionBar from '../Form/ActionBar.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Modal from '../Modals/Modal.vue'
  import TaxonomiesEditor from '../TaxonomiesEditor/TaxonomiesEditor.vue'
  import EditorLocaleDropdown from '../Translations/EditorLanguageDropdown.vue'

  import { capitalize } from '../../util/string.ts'
  import { randomId } from '../../util/random'

  import { ACTION_TYPES as TAXONOMIES_ACTION_TYPES } from '../../store/modules/taxonomies'
  import { ACTION_TYPES as CONFIG_ACTION_TYPES } from '../../store/modules/config'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from '../../store/modules/localization'
  import MODAL_IDS from '../../constants/modal-ids'

  import ConfigMixin from '../../util/ConfigMixin'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {
      ActionBar,
      DsTextarea,
      FormGroup,
      Modal,
      TaxonomiesEditor,
      EditorLocaleDropdown,
      WithConfigurationTabs,
    },
    data () {
      return {
        failed: {
          categories: [],
          domains: [],
          industries: [],
        },
        show: {
          errors: false,
        },
        errorMessage: null,
        messages: {},
        receivedInput: false,
        success: '',
        isSaving: false,
        isUpdated: false,
        activitiesFetched: null,
        membershipsFetched: null,
        technologiesFetched: null,
        stagesFetched: null,
        expertisesFetched: null,
        motivationsFetched: null,
        readinessLevelsFetched: null,
        businessAspectsFetched: null,
        processStepsFetched: null,
        funnelStagesFetched: null,
        categoriesFetched: null,
        aspectsFetched: null,
        domainsFetched: null,
        industriesFetched: null,
        productFeaturesAFetched: null,
        productFeaturesBFetched: null,
        productFeaturesCFetched: null,
        AnnouncementCategoriesFetched: null,
        EventCategoriesFetched: null,
        isEditingActivities: false,
        isEditingMemberships: false,
        isEditingTechnologies: false,
        isEditingStages: false,
        isEditingExpertises: false,
        isEditingMotivations: false,
        isEditingReadinessLevels: false,
        isEditingBusinessAspects: false,
        isEditingProcessSteps: false,
        isEditingFunnelStages: false,
        isEditingCategories: false,
        isEditingAspects: false,
        isEditingDomains: false,
        isEditingIndustries: false,
        isEditingProductFeaturesA: false,
        isEditingProductFeaturesB: false,
        isEditingProductFeaturesC: false,
        isEditingAnnouncementCategories: false,
        isEditingEventCategories: false,
        editingLocale: DEFAULT_LOCALE,
      }
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isProductEnabled () {
        return this.$store.getters.viewActorTypes.includes('Product')
      },
      missingForLocale () {
        return AVAILABLE_LOCALES.reduce((missing, locale) => {
          missing[locale] = false
          return missing
        }, {})
      },
      // Has unsaved changes?
      isEditing () {
        return this.isEditingActivities || this.isEditingCategories || this.isEditingDomains || this.isEditingMemberships || this.isEditingTechnologies || this.isEditingStages || this.isEditingIndustries || this.isEditingFunnelStages || this.isEditingExpertises || this.isEditingMotivations || this.isEditingReadinessLevels || this.isEditingBusinessAspects || this.isEditingAspects || this.isEditingProcessSteps || this.isEditingProductFeaturesA || this.isEditingProductFeaturesB || this.isEditingProductFeaturesC || this.isEditingAnnouncementCategories || this.isEditingEventCategories
      },
      aspectTaxonomyEnabled () {
        return this.$store.getters.aspectTaxonomyEnabled
      },
      readinessTaxonomyEnabled () {
        return this.$store.getters.readinessTaxonomyEnabled
      },
      businessAspectTaxonomyEnabled () {
        return this.$store.getters.businessAspectTaxonomyEnabled
      },
      taxonomyProcessStepsEnabled () {
        return this.$store.getters.taxonomyProcessStepsEnabled
      },
      categoriesTaxonomy () {
        const actorTypes = ['LegalEntity', 'Person', 'Product']
        const byActorType = {}
        const children = []

        for (const actorType of actorTypes) {
          byActorType[actorType] = {
            id: actorType,
            name: actorType,
            disableDrag: true,
            disableEdit: true,
            disableDelete: true,
            children: [],
          }

          children.push(byActorType[actorType])
        }

        if (this.categoriesFetched) {
          const categories = this.categoriesFetched.filter(c => !c.deleted)

          for (const category of categories) {
            if (!actorTypes.includes(category.actor_type)) {
              continue
            }

            byActorType[category.actor_type].children.push({
              id: category.id,
              name: category.name,
              translations: category.translations,
              disableDrag: true,
            })
          }
        }

        for (const actorType in byActorType) {
          byActorType[actorType].children.sort((a, b) => a.name.localeCompare(b.name))
        }

        const label = this.doesTaxonomyAliasExist('categories') ? this.getTaxonomyAlias('categories') : 'Categories'

        return {
          id: 'categories',
          name: label,
          description: 'List of Categories per actor type',
          disableDrag: true,
          disableEdit: true,
          disableDelete: true,
          disableAddChild: true,
          maxDepth: 2,
          children,
        }
      },
      AnnouncementCategoriesTaxonomy () {
        return this.handleComputedTaxonomyById('announcement_category', 'AnnouncementCategories', 'AnnouncementCategories')
      },
      EventCategoriesTaxonomy () {
        return this.handleComputedTaxonomyById('event_category', 'EventCategories', 'EventCategories')
      },
      aspectsTaxonomy () {
        return this.handleComputedTaxonomyById('aspect', 'aspects', 'Aspects')
      },
      domainsTaxonomy () {
        return this.handleComputedTaxonomyById('domain', 'domains', 'Domains')
      },
      industriesTaxonomy () {
        return this.handleComputedTaxonomyById('industry', 'industries', 'Industries')
      },
      productFeaturesATaxonomy () {
        return this.handleComputedTaxonomyById('productFeatureA', 'productFeaturesA', 'Product Features A', true)
      },
      productFeaturesBTaxonomy () {
        return this.handleComputedTaxonomyById('productFeatureB', 'productFeaturesB', 'Product Features B', true)
      },
      productFeaturesCTaxonomy () {
        return this.handleComputedTaxonomyById('productFeaturesC', 'productFeaturesC', 'Product Features C', true)
      },
      technologiesTaxonomy () {
        return this.handleComputedTaxonomyById('technology', 'technologies', 'Technologies', true)
      },
      taxonomies () {
        return [
          this.categoriesTaxonomy,
          this.flatTaxonomy('membershipsFetched', 'memberships', this.getTaxonomyAlias('memberships', false, 'Memberships'), 'List of Memberships'),
          this.flatTaxonomy('activitiesFetched', 'activities', this.doesTaxonomyAliasExist('activities') ? this.getTaxonomyAlias('activities') : 'Activities', 'List of Activities'),
          this.technologiesTaxonomy,
          this.domainsTaxonomy,
          this.flatTaxonomy('stagesFetched', 'stages', this.getTaxonomyAlias('stages', false, 'Stages'), 'List of Stages'),
          this.flatTaxonomy('funnelStagesFetched', 'funnelStages', this.getTaxonomyAlias('funnel_stage', false, 'Funnel Stages'), 'List of Funnel stages'),
          this.industriesTaxonomy,
          this.isProductEnabled ? this.productFeaturesATaxonomy : null,
          this.isProductEnabled ? this.productFeaturesBTaxonomy : null,
          this.isProductEnabled ? this.productFeaturesCTaxonomy : null,
          this.flatTaxonomy('expertisesFetched', 'expertises', this.doesTaxonomyAliasExist('expertises') ? this.getTaxonomyAlias('expertises') : 'Expertises', 'List of Expertises'),
          this.flatTaxonomy('motivationsFetched', 'motivations', this.getTaxonomyAlias('motivations', false, 'Motivations'), 'List of Motivations'),
          this.readinessTaxonomyEnabled ? this.flatTaxonomy('readinessLevelsFetched', 'readinessLevels', this.getTaxonomyAlias('readinessLevels', false, 'Readiness Levels'), 'List of Readiness levels') : null,
          this.taxonomyProcessStepsEnabled ? this.flatTaxonomy('processStepsFetched', 'processSteps', this.getTaxonomyAlias('process_steps', false, 'Process steps'), 'List of Process steps') : null,
          this.businessAspectTaxonomyEnabled ? this.flatTaxonomy('businessAspectsFetched', 'businessAspects', this.getTaxonomyAlias('business_aspects', false, 'Business aspects'), 'List   of Business aspects') : null,
          this.aspectTaxonomyEnabled ? this.aspectsTaxonomy : null,
          this.flatTaxonomy('AnnouncementCategoriesFetched', 'AnnouncementCategories', this.doesTaxonomyAliasExist('announcement_categories') ? this.getTaxonomyAlias('announcement_categories') : 'Announcements', 'List of Announcements'),
          this.flatTaxonomy('EventCategoriesFetched', 'EventCategories', this.doesTaxonomyAliasExist('event_categories') ? this.getTaxonomyAlias('event_categories') : 'Events', 'List of Events'),
        ].filter(x => x !== null)
      },
    },
    methods: {
      flatTaxonomy (dataProperty, id, name, description) {
        const children = []

        if (this[dataProperty]) {
          for (const item of this[dataProperty].filter(x => !x.deleted)) {
            children.push({
              id: item.id,
              name: item.name,
              translations: item.translations,
              disableDrag: true,
            })
          }
        }

        children.sort((a, b) => a.name.localeCompare(b.name))

        return {
          id,
          name,
          description,
          disableDrag: true,
          disableEdit: true,
          disableDelete: true,
          maxDepth: 1,
          children,
        }
      },
      input () {
        this.receivedInput = true
      },
      noInput () {
        this.$nextTick(() => {
          this.receivedInput = false
        })
      },
      extractCreatedUpdatedDeleted (data, parentIdProperty) {
        const created = []
        const updated = []
        const moved = []
        let deleted = []

        for (const item of data) {
          if (item.created && item.deleted) {
            continue
          }

          if (item.created) {
            created.push(item)
          } else if (item.deleted) {
            deleted.push(item)
          } else if (item.moved) {
            // moved.push(item); // Not implemented atm
          } else if (item.updated) {
            updated.push(item)
          }
        }

        if (parentIdProperty) {
          const deletedIds = deleted.map(x => x.id)
          deleted = deleted.filter(x => !deletedIds.includes(x[parentIdProperty]))
        }

        return { created, updated, moved, deleted }
      },
      async updateCategoryValues () {
        let response
        const { created, updated, moved, deleted } = this.extractCreatedUpdatedDeleted(this.categoriesFetched)
        let messages = {
          store: [],
          update: [],
          delete: [],
          error: [],
        }

        for (const item of created) {
          try {
            response = await Categories.post({
              name: item.name,
              translations: item.translations,
              actor_type: item.actor_type,
            })
            resetItemChanges(item)

            messages = this.appendMessage(response, 'store', messages)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of updated) {
          try {
            response = await Categories.post({
              id: item.id,
              name: item.name,
              translations: item.translations,
              actor_type: item.actor_type,
            })
            resetItemChanges(item)

            messages = this.appendMessage(response, 'update', messages)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of moved) {
          try {
            // MOVE API CALL HERE
            await Categories.post({
              id: item.id,
              name: item.name,
              translations: item.translations,
              actor_type: item.actor_type,
            })
            resetItemChanges(item)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of deleted) {
          try {
            response = await Categories.delete(item.id)
            resetItemChanges(item)

            messages = this.appendMessage(response, 'delete', messages)
          } catch (error) {
            console.error(error)
          }
        }

        this.isEditingCategories = false

        return messages
      },
      async updateAspectValues () {
        return this.updateNonFlatTaxonomyValues('aspect', 'aspects', Aspects)
      },
      async updateDomainValues () {
        return this.updateNonFlatTaxonomyValues('domain', 'domains', Domains)
      },
      async updateIndustryValues () {
        return this.updateNonFlatTaxonomyValues('industry', 'industries', Industries)
      },
      async updateProductFeaturesAValues () {
        return this.updateNonFlatTaxonomyValues('productFeatureA', 'productFeaturesA', ProductFeaturesA, true)
      },
      async updateProductFeaturesBValues () {
        return this.updateNonFlatTaxonomyValues('productFeatureB', 'productFeaturesB', ProductFeaturesB, true)
      },
      async updateProductFeaturesCValues () {
        return this.updateNonFlatTaxonomyValues('productFeatureC', 'productFeaturesC', ProductFeaturesC, true)
      },
      async updateTechnologyValues () {
        return this.updateNonFlatTaxonomyValues('technology', 'technologies', Technologies, true)
      },
      async updateNonFlatTaxonomyValues (name, namePlural, apiEndpoint, convertChildrenToChildren) {
        let response
        const { created, updated, moved, deleted } = this.extractCreatedUpdatedDeleted(this[`${namePlural}Fetched`], `${name}_id`)
        let messages = {
          store: [],
          update: [],
          delete: [],
          error: [],
        }

        if (created && created.length > 0) {
          const newValues = this.transformNewTaxonomiesHierarchy(created, namePlural, convertChildrenToChildren)

          try {
            response = await apiEndpoint.post(newValues)

            for (const item of created) {
              resetItemChanges(item)
            }

            messages = this.appendMessage(response, 'store', messages)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of updated) {
          try {
            const postObject = { id: item.id, name: item.name, translations: item.translations }
            postObject[`${name}_id`] = item[`${name}_id`]
            response = await apiEndpoint.post(postObject)
            resetItemChanges(item)

            messages = this.appendMessage(response, 'update', messages)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of moved) {
          try {
            const postObject = { id: item.id, name: item.name, translations: item.translations }
            postObject[`${name}_id`] = item[`${name}_id`]
            await apiEndpoint.post(postObject)
            resetItemChanges(item)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of deleted) {
          try {
            response = await apiEndpoint.delete(item.id)
            resetItemChanges(item)

            messages = this.appendMessage(response, 'delete', messages)
          } catch (error) {
            console.error(error)
          }
        }

        this[`isEditing${capitalize(namePlural)}`] = false

        return messages
      },
      findTaxonomyById (id, taxonomyName) {
        // Currently supported: industries, aspects, domains
        let foundTaxonomy = {}
        const property = taxonomyName + 'Fetched'

        this[property].forEach(taxonomy => {
          if (taxonomy.id === id) {
            foundTaxonomy = taxonomy
          }
        })

        return foundTaxonomy
      },
      async updateFlatTaxonomyValues (resource, dataProperty, isEditingProperty) {
        const { created, updated, deleted } = this.extractCreatedUpdatedDeleted(this[dataProperty])
        let messages = {
          store: [],
          update: [],
          delete: [],
          error: [],
        }

        if (created && created.length > 0) {
          try {
            const response = await resource.post(created.map(item => ({
              name: item.name,
              translations: item.translations,
            })))

            for (const item of created) {
              resetItemChanges(item)
            }

            messages = this.appendMessage(response, 'store', messages)
          } catch (error) {
            console.error(error)
          }
        }

        for (const item of updated) {
          try {
            const response = await resource.post({
              id: item.id,
              name: item.name,
              translations: item.translations,
            })
            resetItemChanges(item)

            messages = this.appendMessage(response, 'update', messages)
          } catch (error) {
            console.error(error)
            messages = this.appendMessage(error, 'error', messages)
          }
        }

        for (const item of deleted) {
          try {
            const response = await resource.delete(item.id)
            resetItemChanges(item)

            messages = this.appendMessage(response, 'delete', messages)
          } catch (error) {
            console.error(error)
            messages = this.appendMessage(error, 'error', messages)
          }
        }

        this[isEditingProperty] = false

        return messages
      },
      transformNewTaxonomiesHierarchy (created, taxonomyName, convertChildrenToChildren) {
        const sendToApi = {}

        const parentKey = {
          industries: 'industry_id',
          aspects: 'aspect_id',
          domains: 'domain_id',
          productFeaturesA: 'parent_id',
          productFeaturesB: 'parent_id',
          productFeaturesC: 'parent_id',
          technologies: 'parent_id',
        }[taxonomyName]

        for (const item of created) {
          const parentId = item[parentKey]

          // If the item has no parent ID (is a top-level item), add it to sendToApi if it hasn't   been added yet by a
          // previous item that had it as a parent ID.
          if (!parentId) {
            if (!sendToApi[item.id]) {
              sendToApi[item.id] = {
                name: item.name,
                translations: item.translations,
                [convertChildrenToChildren ? 'children' : taxonomyName]: [],
              }
            }

            continue
          }

          // If the parent hasn't been added to sendToApi yet, add it
          if (!sendToApi[parentId]) {
            const parent = this.findTaxonomyById(parentId, taxonomyName)

            if (!parent) {
              console.error('Couldn\'t find parent for item, skipping', item)
              continue
            }

            sendToApi[parent.id] = {
              name: parent.name,
              translations: parent.translations,
              [convertChildrenToChildren ? 'children' : taxonomyName]: [],
            }

            // If the parent ID is a number, it's an item that already exists on the server, so   add the ID
            if (typeof parent.id === 'number') {
              sendToApi[parent.id].id = parent.id
            }
          }

          // Add to the parent

          sendToApi[parentId][convertChildrenToChildren ? 'children' : taxonomyName].push({
            name: item.name,
            translations: item.translations,
          })
        }

        return Object.values(sendToApi)
      },
      async save () {
        let messages
        this.success = ''
        this.isSaving = true
        this.messages = {}

        if (this.isEditingActivities) {
          try {
            messages = await this.updateFlatTaxonomyValues(Activities, 'activitiesFetched', 'isEditingActivities')

            this.messages['activities'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Activities, 'activitiesFetched')
        }

        if (this.isEditingMemberships) {
          try {
            messages = await this.updateFlatTaxonomyValues(Memberships, 'membershipsFetched', 'isEditingMemberships')

            this.messages['memberships'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Memberships, 'membershipsFetched')
        }

        if (this.isEditingTechnologies) {
          try {
            messages = await this.updateTechnologyValues()

            this.messages['technologies'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Technologies, 'technologiesFetched', 'children', 'technologies')
        }

        if (this.isEditingStages) {
          try {
            messages = await this.updateFlatTaxonomyValues(Stages, 'stagesFetched', 'isEditingStages')

            this.messages['stages'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Stages, 'stagesFetched')
        }

        if (this.isEditingExpertises) {
          try {
            messages = await this.updateFlatTaxonomyValues(Expertises, 'expertisesFetched', 'isEditingExpertises')

            this.messages['expertises'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Expertises, 'expertisesFetched')
        }

        if (this.isEditingMotivations) {
          try {
            messages = await this.updateFlatTaxonomyValues(Motivations, 'motivationsFetched', 'isEditingMotivations')

            this.messages['motivations'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Motivations, 'motivationsFetched')
        }

        if (this.isEditingReadinessLevels) {
          try {
            messages = await this.updateFlatTaxonomyValues(ReadinessLevels, 'readinessLevelsFetched', 'isEditingReadinessLevels')

            this.messages['readiness levels'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(ReadinessLevels, 'readinessLevelsFetched')
        }

        if (this.isEditingBusinessAspects) {
          try {
            messages = await this.updateFlatTaxonomyValues(BusinessAspects, 'businessAspectsFetched', 'isEditingBusinessAspects')

            this.messages['business aspects'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(BusinessAspects, 'businessAspectsFetched')
        }

        if (this.isEditingProcessSteps) {
          try {
            messages = await this.updateFlatTaxonomyValues(ProcessSteps, 'processStepsFetched', 'isEditingProcessSteps')

            this.messages['process steps'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(ProcessSteps, 'processStepsFetched')
        }

        if (this.isEditingFunnelStages) {
          try {
            messages = await this.updateFlatTaxonomyValues(FunnelStages, 'funnelStagesFetched', 'isEditingFunnelStages')

            this.messages['funnel stages'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(FunnelStages, 'funnelStagesFetched')
        }

        if (this.isEditingCategories) {
          try {
            messages = await this.updateCategoryValues()

            this.messages['categories'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Categories, 'categoriesFetched')
        }

        if (this.isEditingAspects) {
          try {
            messages = await this.updateAspectValues()

            this.messages['aspects'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Aspects, 'aspectsFetched', 'aspects')
        }

        if (this.isEditingDomains) {
          try {
            messages = await this.updateDomainValues()

            this.messages['domains'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Domains, 'domainsFetched', 'domains')
        }

        if (this.isEditingIndustries) {
          try {
            messages = await this.updateIndustryValues()

            this.messages['industries'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(Industries, 'industriesFetched', 'industries')
        }

        if (this.isEditingProductFeaturesA) {
          try {
            messages = await this.updateProductFeaturesAValues()

            this.messages['product features a'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(ProductFeaturesA, 'productFeaturesAFetched', 'children', 'productFeaturesA')
        }
        if (this.isEditingProductFeaturesB) {
          try {
            messages = await this.updateProductFeaturesBValues()

            this.messages['product features b'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(ProductFeaturesB, 'productFeaturesBFetched', 'children', 'productFeaturesB')
        }
        if (this.isEditingProductFeaturesC) {
          try {
            messages = await this.updateProductFeaturesCValues()

            this.messages['product features c'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(ProductFeaturesC, 'productFeaturesBFetched', 'children', 'productFeaturesC')
        }
        if (this.isEditingAnnouncementCategories) {
          try {
            messages = await this.updateFlatTaxonomyValues(AnnouncementCategories, 'AnnouncementCategoriesFetched', 'isEditingAnnouncementCategories')

            this.messages['announcement_categories'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(AnnouncementCategories, 'AnnouncementCategoriesFetched')
        }
        if (this.isEditingEventCategories) {
          try {
            messages = await this.updateFlatTaxonomyValues(EventCategories, 'EventCategoriesFetched', 'isEditingEventCategories')

            this.messages['event_categories'] = messages
          } catch (error) {
            console.error(error)
          }

          this.fetchTaxonomyData(EventCategories, 'EventCategoriesFetched')
        }
        this.isSaving = false
        this.isUpdated = true

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { messages: this.messages })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.TAXONOMY_INFO)
      },
      cancel () {
        if (!window.confirm('Do you want to discard the changes you\'ve made?')) {
          return
        }

        this.fetchAllTaxonomies().then(() => {
          this.isEditingActivities = false
          this.isEditingMemberships = false
          this.isEditingTechnologies = false
          this.isEditingStages = false
          this.isEditingExpertises = false
          this.isEditingMotivations = false
          this.isEditingReadinessLevels = false
          this.isEditingBusinessAspects = false
          this.isEditingProcessSteps = false
          this.isEditingFunnelStages = false
          this.isEditingCategories = false
          this.isEditingAspects = false
          this.isEditingDomains = false
          this.isEditingIndustries = false
          this.isEditingProductFeaturesA = false
          this.isEditingProductFeaturesB = false
          this.isEditingProductFeaturesC = false
          this.isEditingAnnouncementCategories = false
          this.isEditingEventCategories = false
        })

        this.noInput()
      },
      handleRename ({ path, locale, name }) {
        if (name.trim() === '') {
          return
        }

        switch (path[0]) {
          case 'categories':
          case 'aspects':
          case 'domains':
          case 'industries':
          case 'technologies':
          case 'productFeaturesA':
          case 'productFeaturesB':
          case 'productFeaturesC':
            this.handleNonFlatTaxonomyTypeRename({ path, locale, name })
            return

          case 'activities':
          case 'memberships':
          case 'stages':
          case 'expertises':
          case 'motivations':
          case 'AnnouncementCategories':
          case 'EventCategories':
          case 'readinessLevels':
          case 'businessAspects':
          case 'processSteps':
          case 'funnelStages':
            this.handleFlatTaxonomyRename(`${path[0]}Fetched`, `isEditing${capitalize(path[0])}`, {
              path,
              locale,
              name,
            })
            return

          default:
            console.log('rename', path, locale, name)
        }
      },
      handleMove ({ prevPath, nextPath }) {
        if (prevPath[0] !== nextPath[0]) {
          console.error('Moving between taxonomies is not supported yet')
          return
        }

        switch (prevPath[0]) {
          case 'categories':
            this.handleCategoryMove({ prevPath, nextPath })
            return

          case 'aspects':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'aspect')
            return

          case 'domains':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'domain')
            return

          case 'industries':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'industry')
            return

          case 'technologies':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'technology')
            return

          case 'productFeaturesA':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'productFeatureA')
            return

          case 'productFeaturesB':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'productFeatureB')
            return

          case 'productFeaturesC':
            this.handleNonFlatTaxonomyTypeMove({ prevPath, nextPath }, 'productFeatureC')
            return

          default:
            console.log('move', prevPath, nextPath)
        }
      },
      handleAdd ({ path, locale, name }) {
        if (name.trim() === '') {
          return
        }

        switch (path[0]) {
          case 'categories':
            this.handleCategoryAdd({ path, locale, name })
            return
          case 'aspects':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'aspect')
            return
          case 'domains':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'domain')
            return
          case 'industries':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'industry')
            return
          case 'productFeaturesA':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'parent')
            return
          case 'productFeaturesB':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'parent')
            return
          case 'productFeaturesC':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'parent')
            return
          case 'technologies':
            this.handleNonFlatTaxonomyTypeAdd({ path, locale, name }, 'parent')
            return

          case 'activities':
          case 'memberships':
          case 'stages':
          case 'expertises':
          case 'motivations':
          case 'readinessLevels':
          case 'AnnouncementCategories':
          case 'EventCategories':
          case 'businessAspects':
          case 'processSteps':
          case 'funnelStages':
            this.handleFlatTaxonomyAdd(`${path[0]}Fetched`, `isEditing${capitalize(path[0])}`, {
              path,
              locale,
              name,
            })
            return

          default:
            console.log('add', path, locale, name)
        }
      },
      handleDelete ({ path }) {
        switch (path[0]) {
          case 'categories':
          case 'aspects':
          case 'domains':
          case 'industries':
          case 'technologies':
          case 'productFeaturesA':
          case 'productFeaturesB':
          case 'productFeaturesC':
            this.handleNonFlatTaxonomyTypeDelete({ path })
            return

          case 'activities':
          case 'memberships':
          case 'stages':
          case 'expertises':
          case 'motivations':
          case 'readinessLevels':
          case 'businessAspects':
          case 'processSteps':
          case 'funnelStages':
          case 'AnnouncementCategories':
          case 'EventCategories':
            this.handleFlatTaxonomyDelete(`${path[0]}Fetched`, `isEditing${capitalize(path[0])}`, { path })
            return

          default:
            console.log('delete', path)
        }
      },
      handleCategoryMove ({ prevPath, nextPath }) {
        if (nextPath.length !== 2) {
          return
        }

        const [id] = prevPath.slice(-1)
        const [actorType] = nextPath.slice(-1)

        for (const category of this.categoriesFetched) {
          if (category.id === id) {
            if (!category.original_actor_type) {
              category.original_actor_type = category.actor_type
            }

            category.actor_type = actorType
            category.moved = (category.actor_type !== category.original_actor_type)
            break
          }
        }

        this.isEditingCategories = true
      },
      handleCategoryAdd ({ path, locale, name }) {
        const [actorType] = path.slice(-1)

        this.categoriesFetched.push({
          id: randomId(),
          name,
          translations: {
            [locale]:
              { name },
          },
          actor_type: actorType,
          created: true,
          updated: false,
          moved: false,
          deleted: false,
        })

        this.isEditingCategories = true
      },
      handleNonFlatTaxonomyTypeRename ({ path, locale, name }) {
        const taxonomyTypeName = path[0]
        const fetchedValues = this[`${path[0]}Fetched`]
        const [id] = path.slice(-1)

        for (const taxonomyType of fetchedValues) {
          if (taxonomyType.id === id) {
            if (locale === 'en') taxonomyType.name = name
            if (!taxonomyType.translations[locale]) {
              taxonomyType.translations[locale] = {}
            }
            taxonomyType.translations[locale].name = name
            taxonomyType.updated = true
            break
          }
        }
        this[`isEditing${capitalize(taxonomyTypeName)}`] = true
      },
      handleNonFlatTaxonomyTypeDelete ({ path }) {
        const [id] = path.slice(-1)

        for (const taxonomyType of this[`${path[0]}Fetched`]) {
          if (taxonomyType.id === id) {
            taxonomyType.deleted = true
            break
          }
        }
        this[`isEditing${capitalize(path[0])}`] = true
      },
      handleNonFlatTaxonomyTypeMove ({ prevPath, nextPath }, taxonomyTypeName) {
        const taxonomyTypeNamePlural = prevPath[0]
        const [id] = prevPath.slice(-1)
        let [parentId] = nextPath.slice(-1)

        if (parentId === taxonomyTypeNamePlural) {
          parentId = null
        }

        for (const taxonomyType of this[`${taxonomyTypeNamePlural}Fetched`]) {
          if (taxonomyType.id === id) {
            if (!taxonomyType[`original_${taxonomyTypeName}_id`]) {
              taxonomyType[`original_${taxonomyTypeName}_id`] = taxonomyType[`${taxonomyTypeName}_id`]
            }

            taxonomyType[`${taxonomyTypeName}_id`] = parentId
            taxonomyType.moved = (taxonomyType[`${taxonomyTypeName}_id`] !== taxonomyType[`original_${taxonomyTypeName}_id`])
            break
          }
        }

        if (parentId !== null) {
          for (const taxonomyType of this[`${taxonomyTypeNamePlural}Fetched`]) {
            if (taxonomyType[`${taxonomyTypeName}_id`] === id) {
              if (!taxonomyType[`original_${taxonomyTypeName}_id`]) {
                taxonomyType[`original_${taxonomyTypeName}_id`] = taxonomyType[`${taxonomyTypeName}_id`]
              }

              taxonomyType[`${taxonomyTypeName}_id`] = parentId
              taxonomyType.moved = (taxonomyType[`${taxonomyTypeName}_id`] !== taxonomyType[`original_${taxonomyTypeName}_id`])
            }
          }
        }
        this[`isEditing${taxonomyTypeNamePlural}`] = true
      },
      handleNonFlatTaxonomyTypeAdd ({ path, locale, name }, taxonomyTypeName) {
        const taxonomyTypeNamePlural = path[0]
        let [parentId] = path.slice(-1)

        if (parentId === taxonomyTypeNamePlural) {
          parentId = null
        }

        const newEntry = {
          id: randomId(),
          name,
          translations: {
            [locale]:
              { name },
          },
          created: true,
          updated: false,
          moved: false,
          deleted: false,
        }
        newEntry[`${taxonomyTypeName}_id`] = parentId
        this[`${taxonomyTypeNamePlural}Fetched`].push(newEntry)
        this[`isEditing${capitalize(path[0])}`] = true
      },
      handleFlatTaxonomyRename (dataProperty, isEditingProperty, { path, locale, name }) {
        const [id] = path.slice(-1)

        for (const item of this[dataProperty]) {
          if (item.id === id) {
            if (locale === 'en') item.name = name
            if (!item.translations[locale]) {
              item.translations[locale] = {}
            }
            item.translations[locale].name = name
            item.updated = true
            break
          }
        }

        this[isEditingProperty] = true
      },
      handleFlatTaxonomyAdd (dataProperty, isEditingProperty, { locale, name }) {
        this[dataProperty].push({
          id: randomId(),
          name,
          translations: {
            [locale]:
              { name },
          },
          created: true,
          updated: false,
          moved: false,
          deleted: false,
        })

        this[isEditingProperty] = true
      },
      handleFlatTaxonomyDelete (dataProperty, isEditingProperty, { path }) {
        const [id] = path.slice(-1)

        for (const item of this[dataProperty]) {
          if (item.id === id) {
            item.deleted = true
            break
          }
        }

        this[isEditingProperty] = true
      },
      fetchAllTaxonomies () {
        return Promise.all([
          this.fetchTaxonomyData(Activities, 'activitiesFetched'),
          this.fetchTaxonomyData(Memberships, 'membershipsFetched'),
          this.fetchTaxonomyData(Stages, 'stagesFetched'),
          this.fetchTaxonomyData(Expertises, 'expertisesFetched'),
          this.fetchTaxonomyData(Motivations, 'motivationsFetched'),
          this.fetchTaxonomyData(ReadinessLevels, 'readinessLevelsFetched'),
          this.fetchTaxonomyData(BusinessAspects, 'businessAspectsFetched'),
          this.fetchTaxonomyData(ProcessSteps, 'processStepsFetched'),
          this.fetchTaxonomyData(FunnelStages, 'funnelStagesFetched'),
          this.fetchTaxonomyData(Categories, 'categoriesFetched'),
          this.fetchTaxonomyData(AnnouncementCategories, 'AnnouncementCategoriesFetched', 'AnnouncementCategories'),
          this.fetchTaxonomyData(EventCategories, 'EventCategoriesFetched', 'EventCategories'),
          this.fetchTaxonomyData(Aspects, 'aspectsFetched', 'aspects'),
          this.fetchTaxonomyData(Domains, 'domainsFetched', 'domains'),
          this.fetchTaxonomyData(Industries, 'industriesFetched', 'industries'),
          this.fetchTaxonomyData(Technologies, 'technologiesFetched', 'children', 'technologies'),
          this.fetchTaxonomyData(ProductFeaturesA, 'productFeaturesAFetched', 'children', 'productFeaturesA'),
          this.fetchTaxonomyData(ProductFeaturesB, 'productFeaturesBFetched', 'children', 'productFeaturesB'),
          this.fetchTaxonomyData(ProductFeaturesC, 'productFeaturesCFetched', 'children', 'productFeaturesC'),
        ])
      },
      fetchTaxonomyData (resource, property, flattenChildrenProperty, targetFlattenChildProperty) {
        return resource.get().then(items => {
          const results = []

          for (const item of items) {
            prepareAndAddTaxonomyItem(results, item, flattenChildrenProperty, targetFlattenChildProperty)
          }

          this[property] = results
        })
      },
      appendMessage (response, type, messages) {
        if (!response.message) {
          return messages
        }

        messages[type].push(response.message)

        return messages
      },
      handleComputedTaxonomyById (taxonomyTypeName, taxonomyTypeNamePlural, defaultLabel, handleChildrenAsChildren) {
        const byId = {}
        const children = []

        if (this[taxonomyTypeNamePlural + 'Fetched']) {
          const taxonomyTypeEntries = this[taxonomyTypeNamePlural + 'Fetched'].filter(c => !c.deleted)

          for (const taxonomyType of taxonomyTypeEntries) {
            byId[taxonomyType.id] = {
              id: taxonomyType.id,
              name: taxonomyType.name,
              translations: taxonomyType.translations,
              children: [],
              disableDrag: true,
            }
          }

          for (const taxonomyType of taxonomyTypeEntries) {
            const keyName = handleChildrenAsChildren ? 'parent_id' : `${taxonomyTypeName}_id`

            if (taxonomyType[keyName] !== null) {
              if (byId[taxonomyType[keyName]]) {
                byId[taxonomyType[keyName]].children.push(byId[taxonomyType.id])
              }
            } else {
              children.push(byId[taxonomyType.id])
            }
          }
        }

        for (const id in byId) {
          byId[id].children.sort((a, b) => a.name.localeCompare(b.name))
        }

        children.sort((a, b) => a.name.localeCompare(b.name))

        var taxonomyNameDisplayName = defaultLabel

        const label = this.doesTaxonomyAliasExist(taxonomyTypeNamePlural) ? this.getTaxonomyAlias(taxonomyTypeNamePlural) : defaultLabel

        return {
          id: taxonomyTypeNamePlural,
          name: label,
          description: `List of ${taxonomyNameDisplayName}`,
          disableDrag: true,
          disableEdit: true,
          disableDelete: true,
          maxDepth: 2,
          children,
        }
      },
    },
    mixins: [ConfigMixin],
    mounted () {
      this.fetchAllTaxonomies()
    },
    beforeUnmount () {
      if (this.isUpdated) {
        this.$store.dispatch(TAXONOMIES_ACTION_TYPES.REFRESH_ALL_TAXONOMIES)
        this.$store.dispatch(CONFIG_ACTION_TYPES.REFRESH_CONFIG)
      }
    },
    watch: {
      isEditing (isEditing) {
        this.$store.commit(UI_MUTATION_TYPES.SET_PREVENT_DATALAB_NAVIGATION, isEditing)
      },
    }
  })

  function prepareAndAddTaxonomyItem (results, item, flattenChildrenProperty, targetFlattenChildProperty) {
    item.created = false
    item.updated = false
    item.moved = false
    item.deleted = false
    results.push(item)

    if (flattenChildrenProperty && item[flattenChildrenProperty] && Array.isArray(item[flattenChildrenProperty])) {
      for (const child of item[flattenChildrenProperty]) {
        if (targetFlattenChildProperty) {
          item[targetFlattenChildProperty] = item[flattenChildrenProperty]
          flattenChildrenProperty = targetFlattenChildProperty
        }
        prepareAndAddTaxonomyItem(results, child, flattenChildrenProperty)
      }

      delete item[targetFlattenChildProperty || flattenChildrenProperty]
    }
  }

  function resetItemChanges (item) {
    item.created = false
    item.updated = false
    item.moved = false
  }
</script>

<style>
  .failed-item {
    margin: 10px 0;
  }
</style>
