<template>
  <div class="card__info__social">
    <social-item :urls="company.facebook">
      <icon size="18" name="facebook-logo"/>
    </social-item>
    <social-item :urls="company.twitter">
      <icon size="14" style="margin-bottom: -2px;" name="twitter-logo"/>
    </social-item>
    <social-item :urls="company.reddit">
      <icon :size="iconSize" name="reddit-logo"/>
    </social-item>
    <a class="laicos-item" v-if="company.linkedin" :href="company.linkedin" target="_blank">
      <icon size="18" name="linkedin-logo"/>
    </a>
    <a class="laicos-item" v-if="company.vimeo" :href="company.vimeo" target="_blank" style="bottom: -2px;">
      <icon :size="iconSize" name="vimeo-logo"/>
    </a>
    <a class="laicos-item" v-if="company.youtube" :href="company.youtube" target="_blank" style="bottom: -2px;">
      <icon :size="iconSize" name="youtube-play"/>
    </a>
    <a class="laicos-item" v-if="company.instagram" :href="company.instagram" target="_blank">
      <icon :size="iconSize" name="instagram-logo"/>
    </a>
    <a class="laicos-item" v-if="company.kenniswest" :href="company.kenniswest" target="_blank" style="bottom: -1px;">
      <icon :size="iconSize" name="west-flanders"/>
    </a>
    <a class="laicos-item" v-if="company.crunchbase" :href="company.crunchbase" target="_blank" style="bottom: 1px;">
      <icon size="18" name="crunchbase-logo-letters"/>
    </a>
    <a class="laicos-item" v-if="company.wikipedia" :href="company.wikipedia" target="_blank" style="bottom: 1px;">
      <icon :size="iconSize" name="wikipedia-logo"/>
    </a>
    <a class="laicos-item" v-if="company.rss_feed" :href="company.rss_feed" target="_blank" style="bottom: 1px;">
      <icon :size="iconSize" name="rss"/>
    </a>
    <a class="laicos-item" v-if="company.url && showWebsiteUrl" :href="company.url" target="_blank" style="bottom: -1px;">
      <icon :size="iconSize" name="world"/>
    </a>
    <template v-if="false && expandOtherUrls && company.other_urls">
      <template v-for="url in company.other_urls">
        <a class="laicos-item" :href="url" :title="url" target="_blank" style="bottom: -1px;">
          <icon :size="iconSize" name="world"/>
        </a>
      </template>
    </template>
    <template v-if="false && !expandOtherUrls && company.other_urls && company.other_urls.length > 0">
      <a class="laicos-item" :href="company.other_urls[0]" :title="company.other_urls[0]" target="_blank" style="bottom: -1px;">
        <icon :size="iconSize" name="world"/>
      </a>
      <button v-if="company.other_urls.length > 1" style="display: inline-flex;width: auto !important; vertical-align: top; padding: 0.5em;" type="button" class="a subtle-button"
              @click.prevent="toggleExpandOtherUrls">Show {{ company.other_urls.length - 1 }} more urls...
      </button>
    </template>
  </div>
</template>

<script>
  import SocialItem from './SocialItem.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      company: Object,
      iconSize: {
        type: String,
        default: 'regular',
      },
      showWebsiteUrl: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        expandOtherUrls: false
      }
    },

    components: {
      SocialItem,
    },
    methods: {
      toggleExpandOtherUrls () {
        this.expandOtherUrls = !this.expandOtherUrls
      }
    },
  })
</script>
