<template>
  <div class="graph-view-container">
    <div class="zoom-controls">
      <ds-button @click="clickZoomIn" label="+" variant="outline"/>
      <ds-button @click="clickZoomOut" label="-" variant="outline"/>
    </div>
    <div class="zoom-instructions">
      {{ zoomInstructions }}
    </div>
    <div class="graph-controls">
      <ds-button @click="clickExpand" label="Expand" size="small" variant="primary" icon="arrow-expand" :disabled="isGraphLoading || isLoading"/>
      <ds-button @click="clickContract" label="Shrink" size="small" variant="primary" icon="arrow-shrink" :disabled="isGraphLoading || isLoading"/>
      <ds-button @click="clickResetView" label="Reset view" size="small" icon="arrow-rotate-left" variant="primary"/>
      <div class="loading-indicator" v-if="isLoading">Loading…</div>
    </div>
    <div ref="graphView" class="graph-view">
    </div>

    <div class="graph-view-aside">
      <GraphViewConnections
        :actor="actor"
        :connectionTypes="connectionsByType"
        :hiddenTypes="hiddenTypes"
        @remove="removeRelation"
        @setVisibility="setVisibility"
      />
    </div>
  </div>
</template>

<script>
  import cytoscape from 'cytoscape'
  import cola from 'cytoscape-cola'
  import popper from 'cytoscape-popper'
  // Not using this 4.x.x legacy version causes problems with the hover text on connection arrows due, mostly because it relies on popper v1 instead of v2
  import tippy from 'tippy.js-legacy'
  import cxtmenu from 'cytoscape-cxtmenu'

  import _groupBy from 'lodash/groupBy'
  import _max from 'lodash/max'
  import GraphViewConnections from './GraphViewConnections.vue'
  import DsButton from '../../components/DsButton/DsButton.vue'
  import _get from 'lodash/get'

  import { fetchConnectionActors } from '../../api/actors'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'

  import { trackHeapEvent } from '../../util/analytics'

  import CompanyMixin from '../../util/CompanyMixin'
  import FiltersMixin from '../../util/FiltersMixin'

  // Use the cola layout
  // @link: https://github.com/cytoscape/cytoscape.js-cola
  cytoscape.use(cola)
  cytoscape.use(popper)

  // Cola lyout options for the Cytoscape object
  const layoutOptions = {
    name: 'cola',
    animate: false, // Let it be false, otherwise we sometimes get errors on re-draw (htmlImage not found)
    convergenceThreshold: 100, // end layout sooner, may be a bit lower quality
    edgeLength: 150,
    maxSimulationTime: 5000
  }

  // Options for the Cytoscape cxt menu
  cytoscape.use(cxtmenu)

  export default {
    props: {
      actor: Object,
      connections: Array
    },
    data () {
      return {
        connectionsByType: [],
        hiddenTypes: [],
        isLoading: true,
        isGraphLoading: false,
        cyMenu: null,
        mounted: false
      }
    },
    beforeUnmount () {
      this.mounted = false

      if (this.cy) {
        this.cy.removeListener('layoutstart')
        this.cy.removeListener('layoutready')
        this.cy.removeListener('layoutstop')

        this.cy.destroy()
      }

      if (this.cyMenu) {
        this.cyMenu.destroy()
      }
    },
    computed: {
      metaDataListsPromise () {
        return this.$store.state.actorRelationships.metaDataListsPromise
      },
      fetchingMetaDataLists () {
        return this.$store.state.actorRelationships.fetchingMetaDataLists
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      ecosystemMetaDataLists () {
        return this.$store.getters.relationshipMetaDataLists
      },
      zoomInstructions () {
        var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

        if (mac) {
          return 'Hold the ⌘-key while scrolling to zoom in and out. Right click on a node to see more options.'
        }

        return 'Hold the ctrl-key while scrolling to zoom in and out. Right click on a node to see more options.'
      },
      cxtOptions () {
        return {
          menuRadius: 70, // the radius of the circular menu in pixels
          selector: 'node', // elements matching this Cytoscape.js selector will trigger cxtmenus
          commands: [ // an array of commands to list in the menu or a function that returns the array
            { // example command
              fillColor: 'rgba(200, 200, 200, 0.7)', // optional: custom background color for item
              content: '<div class="svg-icon svg-icon--extra-small svg-icon--search"><svg viewBox=\"2 2 20 20\"><path fill="#fff" d=\"M9.5 3A6.5 6.5 0 0 1 16 9.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 9.5 16 6.5 6.5 0 0 1 3 9.5 6.5 6.5 0 0 1 9.5 3m0 2C7 5 5 7 5 9.5S7 14 9.5 14 14 12 14 9.5 12 5 9.5 5z\"/></svg></div>', // html/text content to be displayed in the menu
              contentStyle: {}, // css key:value pairs to set the command's css in js if you want
              select: ele => { // a function to execute when the command is selected
                var actorId = ele.id() // `ele` holds the reference to the active element
                var selectedActor = this.actors.find(actor => actor.id === ele.data().id)

                if (!selectedActor) {
                  selectedActor = { id: actorId }
                }

                this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, selectedActor)
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: { actorId: actorId, isPreview: true }})
              },
              enabled: true // whether the command is selectable
            },
            { // example command
              fillColor: 'rgba(200, 200, 200, 0.9)', // optional: custom background color for item
              content: '<div class="svg-icon svg-icon--extra-small svg-icon--account"><svg viewBox=\"2 2 20 20\"><path fill="#fff" d=\"M12 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4z\"/></svg></div>',
              contentStyle: {}, // css key:value pairs to set the command's css in js if you want
              select: ele => { // a function to execute when the command is selected
                var actorId = ele.id()

                // Close the side panel
                this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)

                // Go to the actor detail page of the clicked actor
                this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, actorId)
                this.$router.push(`/actors/${actorId}`)
              },
              enabled: true // whether the command is selectable
            }
          ], // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
          fillColor: 'rgba(0, 0, 0, 0.9)', // the background colour of the menu
          activeFillColor: this.$store.getters.primaryColor, // the colour used to indicate the selected command
          activePadding: 20, // additional size in pixels for the active command
          indicatorSize: 24, // the size in pixels of the pointer to the active command
          separatorWidth: 3, // the empty spacing in pixels between successive commands
          spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
          minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight
          maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight
          openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
          itemColor: 'white', // the colour of text in the command's content
          itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
          zIndex: 9999, // the z-index of the ui div
          atMouse: false // draw menu at mouse position
        }
      },
    },
    methods: {
      initCytoscape () {
        // Cache of actors
        this.actors = []

        this.cy = cytoscape({
          container: this.$refs.graphView,
          elements: this.getInitialGraphData(),
          maxZoom: 2,
          minZoom: 0.5,
          style: [
            {
              selector: 'node',
              style: {
                'background-color': 'white',
                'background-image': (ele) => {
                  const actor = this.actors.find(actor => actor.id === ele.data().id)

                  // This currently doesn't work 100%, sometimes the library - cytoscape - breaks and the entire graph goes haywire
                  // with the error: The HTMLImageElement provided is in the 'broken' state.
                  // https://github.com/cytoscape/cytoscape.js/issues/1814
                  /* if (actor && actor.logo && ! actor.logo.startsWith('http')) {
                    return actor.logo;
                  } */

                  var firstCharacter = 'A'

                  if (ele.data() && ele.data().name && (typeof ele.data().name === 'string' || ele.data().name instanceof String)) {
                    name = ele.data().name.replace(/[^a-z]/gi, '')

                    if (name && name.length > 0) {
                      firstCharacter = name.charAt(0).toUpperCase()
                    }
                  }

                  return `/images/${firstCharacter}.png`
                },
                'background-fit': 'cover cover',
                'border-color': '#B2B2B2',
                'border-width': 3,
                'color': '#7a7a7a',
                'font-size': 12,
                'label': 'data(name)',
                'text-margin-y': 10,
                'text-background-color': 'white',
                'text-background-opacity': 1,
                'text-background-padding': 1,
                'text-border-color': '#B2B2B2',
                'text-border-opacity': 1,
                'text-border-width': 1,
                'text-valign': 'bottom',
              },
            },
            {
              selector: 'node[type="central"]',
              style: {
                'background-image': (ele) => {
                  var firstCharacter = 'A'

                  if (this.actor && this.actor.name && (typeof this.actor.name === 'string' || this.actor.name instanceof String)) {
                    name = this.actor.name.replace(/[^a-z]/gi, '')

                    if (name && name.length > 0) {
                      firstCharacter = name.charAt(0).toUpperCase()
                    }
                  }

                  const result = [
                    `/images/${firstCharacter}.png`,
                  ]

                  if (this.actor && this.actor.logo && !this.actor.logo.startsWith('http')) {
                    result.push(this.actor.logo)
                  }

                  return result
                },
                'border-color': '#B2B2B2',
                'border-width': 6,
              },
            },
            {
              selector: 'edge',
              style: {
                'width': 1,
                'curve-style': 'bezier',
                'line-color': '#ccc',
                'target-arrow-color': '#ccc',
                'target-arrow-shape': 'triangle',
              },
            },
            {
              selector: 'node:selected',
              style: {
                // Hide the large gray square around the node indicating that it is selected or actively dragged
                'overlay-opacity': 0,
              },
            },
            {
              selector: 'node:active',
              style: {
                // Hide the large gray square around the node indicating that it is selected or actively dragged
                'overlay-opacity': 0,
              },
            },
            ...this.getNodeStyles(),
            ...this.getEdgeStyles(),
          ],
          layout: layoutOptions,
        })

        // Add the context menu
        this.menu = this.cy.cxtmenu(this.cxtOptions)

        // Disable zooming via scrolling
        this.cy.userZoomingEnabled(false)

        // Disable the expand/shrink buttons when the layout is running
        this.cy.on('layoutstart', (event) => {
          this.isGraphLoading = true
        })

        this.cy.on('mouseover', (event) => {
          // TODO change type to meta data when it's available
          if (_get(event, 'target._private.data.source') && _get(event, 'target._private.data.target')) {
            const data = event.target._private.data
            const type = data.type
            const score = data.score
            const comment = data.comment
            const status = data.status
            const start = data.start
            const end = data.end
            const node = event.target
            const ref = node.popperRef()

            node.tippy = tippy(ref, {
              content: () => {
                const content = document.createElement('div')
                let label = type

                if (!type.startsWith('sends')) {
                  label = this.ecosystemRelationships.find(r => r.name === type).label
                } else if (type === 'sends') {
                  // the case where the "sends" relationship is used without tags
                  label = 'sends data to'
                } else {
                  label += ' to'
                }

                const fillerWords = ['<br>status: ', '<br>start date: ', '<br>end date: ', '<br>score: ', '<br>comment: ']
                const suffix = [status, start, end, score, comment].reduce((acc, value, index) => {
                  return acc + (value ? fillerWords[index] + value : '')
                }, '')

                content.innerHTML = label + `<span style="opacity: 0.5">${suffix}</span>`

                return content
              },
              trigger: 'manual',
            })
            node.tippy.show()
          }
        })

        this.cy.on('mouseout', (event) => {
          const node = event.target
          if (node.tippy) {
            node.tippy.hide()

            setTimeout(() => {
              // destroy after hide animation to improve performance
              node.tippy.destroy()
            }, 200)
          }
        })

        this.cy.on('layoutready', function (event) {
          this.isGraphLoading = true
        })

        this.cy.on('layoutstop', (event) => {
          this.isGraphLoading = false
        })

        // Set the layout options and run the layout
        this.layout = this.cy.layout(layoutOptions).run()

        this.updateSidebarData()

        // Disable pan & zoom during dragging because it looks jittery
        this.cy.on('drag', () => {
          this.cy.zoomingEnabled(false)
          this.cy.panningEnabled(false)
          this.layout.run()
        })

        this.cy.on('dragfree', () => {
          this.cy.zoomingEnabled(true)
          this.cy.panningEnabled(true)
        })

        // Expand or contract actor when tapping one
        this.cy.on('tap', event => {
          if (!event.target[0]) return

          const targetData = event.target[0].data()

          if (!targetData.source) {
            const actor = this.actors.find(actor => actor.id === targetData.id)

            if (actor) {
              const node = this.cy.getElementById(actor.id)
              const { isExpanded } = node.data()

              if (isExpanded) {
                this.contractActor(actor)
              } else {
                this.expandActors([actor])
              }
            }
          }
        })

        // We only know which actors the central actor is connected to, but we don't know
        // which connections these actors have. fetchConnectedActors fetches the connections
        // of these actors and returns those that can be added to the graph view (so only
        // connections of currently visible actors).
        this.fetchConnectedActors(this.connections.map(c => c.id))
          .then((connections) => {
            if (!this.mounted) return
            this.cy.add(connections)
            this.updateSidebarData()
            this.layout.run()
            this.isLoading = false
          })
      },
      clickExpand (e) {
        e.preventDefault()
        trackHeapEvent('actorDetail.graphView.clickExpandButton')
        this.expandActors(this.actors)
      },
      clickContract (e) {
        e.preventDefault()
        trackHeapEvent('actorDetail.graphView.clickShrinkButton')
        this.contractActors(this.actors)
      },
      clickResetView (e) {
        e.preventDefault()
        this.cy.destroy()
        this.initCytoscape()
        trackHeapEvent('actorDetail.graphView.clickResetViewButton')
      },
      getGraphViewCenter () {
        return {
          x: this.$refs.graphView.offsetLeft + this.$refs.graphView.offsetWidth / 2,
          y: this.$refs.graphView.offsetTop + this.$refs.graphView.offsetHeight / 2,
        }
      },
      clickZoomOut (e) {
        e.preventDefault()
        const zoom = this.cy.zoom() * 0.75

        this.cy.animate({
          center: this.getGraphViewCenter(),
          duration: 100,
          zoom: zoom >= this.cy.minZoom() ? zoom : this.cy.minZoom(),
        })
      },
      clickZoomIn (e) {
        e.preventDefault()
        const zoom = this.cy.zoom() * 1.25

        this.cy.animate({
          center: this.getGraphViewCenter(),
          duration: 100,
          zoom: zoom <= this.cy.maxZoom() ? zoom : this.cy.maxZoom(),
        })
      },
      expandActors (actors) {
        if (this.isLoading) {
          return
        }

        this.isLoading = true

        // Temporarily restore any nodes and edges that are filtered so we won't miss any
        this.filteredNodes && this.filteredNodes.restore()
        this.filteredEdges && this.filteredEdges.restore()

        const existingNodes = this.cy.json().elements.nodes
        const existingActors = actors.filter(actor => existingNodes.some(node => node.data.id === actor.id))
        const addedActorIds = []
        const nodesToAdd = []
        const edgesToAdd = []

        // Set isExpanded flag on all visible actors
        this.cy.elements().nodes().forEach(existingNode => {
          existingNode.data('isExpanded', true)
        })

        for (const actor of existingActors) {
          // Find actors this actor is connected to
          const connections = this.getConnectionsFromActor(actor)

          // Find actors that have not been added yet and add them to nodesToAdd
          const filteredConnections = connections.filter(connection =>
            !existingNodes.some(existingNode => existingNode.data.id === connection.to),
          )

          if (filteredConnections.length) {
            nodesToAdd.push(...filteredConnections.map(connection => {
              return {
                group: 'nodes',
                data: {
                  id: connection.to,
                  name: connection.to_name,
                  legend: 'legend_id_' + (connection.legend && connection.legend.value)
                }
              }
            },
            ))
            addedActorIds.push(...filteredConnections.map(c => c.to))
          }

          // Find edges that have not been added yet
          const edges = this.getEdgesFromActor(actor)
          const filteredEdges = edges.filter(edge =>
            nodesToAdd.some(node => node.data.id === edge.data.target),
          )

          if (filteredEdges.length) {
            edgesToAdd.push(...filteredEdges.map(edge => ({
              group: 'edges',
              ...edge,
            })))
          }
        }

        // Add nodes & edges to the graph view if there are any
        if (nodesToAdd.length) {
          this.cy.add(nodesToAdd)
          this.cy.add(edgesToAdd)
          this.applyFilters()
          this.updateSidebarData()

          // Fetch connections of newly added actors
          return this.fetchConnectedActors(addedActorIds).then((connections) => {
            if (!this.mounted) return
            this.cy.add(connections)
            this.applyFilters()
            this.updateSidebarData()
            this.isLoading = false
          })
        }

        return new Promise((resolve) => {
          this.isLoading = false
        })
      },
      contractActors (actors) {
        if (this.isLoading) {
          return
        }

        this.isLoading = true

        this.filteredNodes && this.filteredNodes.restore()
        this.filteredEdges && this.filteredEdges.restore()

        const elements = this.cy.elements()
        const nodes = elements.nodes()
        const aStars = []
        const aStarNodes = []

        nodes.forEach(node => {
          const aStar = elements.aStar({
            root: nodes[0],
            goal: node,
          })
          aStars.push({
            node,
            distance: aStar.distance,
          })
        })

        const distances = aStars.map(a => a.distance)
        const maxDistance = _max(distances)

        if (maxDistance > 1) {
          const aStarsToRemove = aStars.filter(a => a.distance === maxDistance)
          aStarsToRemove.forEach((aStar) => {
            aStar.node.remove()
            const actorId = aStar.node.data().id
          })
          nodes.data('isExpanded', false)
        }

        this.updateSidebarData()
        this.applyFilters()

        this.isLoading = false
      },
      // Traverse the graph from a given node to find all nodes that are further away from the central
      // node than the node itself. These nodes can then be removed when the node needs to be contracted.
      // For example, these nodes: A - B - C - D - [CENTRAL NODE]. When calling this function for node C,
      // nodes A and B will be returned, unless A or B have a more direct connection to the central node.
      traverseNodeToContract (node, centralNode, elements) {
        const result = []

        const nodeId = node.data().id
        const aStarNodeToCentralNode = elements.aStar({
          root: centralNode,
          goal: node,
        })
        const connectedEdges = node.connectedEdges()

        connectedEdges.forEach(connectedEdge => {
          const { source, target } = connectedEdge.data()
          const connectedNodeId = source === nodeId ? target : source
          const connectedNode = this.cy.getElementById(connectedNodeId)
          const aStarConnectedNodeToCentralNode = elements.aStar({
            root: centralNode,
            goal: connectedNode,
          })
          if (aStarConnectedNodeToCentralNode.distance > aStarNodeToCentralNode.distance) {
            result.push(connectedNode)

            const connectedResult = this.traverseNodeToContract(
              connectedNode,
              centralNode,
              elements,
            )

            if (connectedResult.length) {
              result.push(...connectedResult)
            }
          }
        })
        return result
      },
      contractActor (actor) {
        const centralNode = this.cy.elements().nodes()[0]
        const nodeToContract = this.cy.getElementById(actor.id)
        const nodesToRemove = this.traverseNodeToContract(
          nodeToContract,
          centralNode,
          this.cy.elements(),
        )
        for (const node of nodesToRemove) {
          node.remove()
        }
        nodeToContract.data('isExpanded', false)
        this.updateSidebarData()
        this.applyFilters()
      },
      // Get the initial graph data. This data includes the central actor and other actors it is connected to
      getInitialGraphData () {
        // var legend = this.getLegendValue(this.actor)

        const centralNode = {
          group: 'nodes',
          data: {
            id: this.actor.id,
            legend: 'legend_id_' + this.getLegendValue(this.actor),
            name: this.actor.name,
            type: 'central',
          },
        }

        const nodes = this.connections
          .map((connection) => {
            return {
              group: 'nodes',
              data: {
                id: connection.id,
                legend: 'legend_id_' + this.getLegendValue(connection),
                name: connection.name,
                type: connection.type,
              },
            }
          })

        const edges = []

        for (const connection of this.connections) {
          const relationshipType = this.ecosystemRelationships.find(t => t.name === connection.type)
          const source = relationshipType.is_forward_connection ? centralNode.data.id : connection.id
          const target = relationshipType.is_forward_connection ? connection.id : centralNode.data.id
          const score = connection.score
          const comment = connection.comment
          const start = connection.start
          const end = connection.end
          const status = connection.status
          const forwardType = relationshipType.is_forward_connection
            ? relationshipType.name
            : relationshipType.inverse_name

          if (source && target) {
            if (connection.tags && connection.tags.length > 0 && forwardType === 'sends') {
              for (const tag of connection.tags) {
                edges.push({
                  group: 'edges',
                  data: {
                    id: `${source}${target}${forwardType}${tag}`,
                    target,
                    source,
                    score,
                    comment,
                    start,
                    end,
                    status,
                    type: 'sends ' + tag,
                  },
                })
              }
            } else if (forwardType !== 'sends') {
              edges.push({
                group: 'edges',
                data: {
                  id: `${source}${target}${forwardType}`,
                  target,
                  source,
                  score,
                  comment,
                  start,
                  end,
                  status,
                  type: forwardType,
                },
              })
            }
          }
        }

        return [centralNode].concat(nodes).concat(edges)
      },
      // Get array of connections from and to given actor
      getConnectionsFromActor (actor) {
        const result = []

        for (const key of this.ecosystemRelationships.map(t => t.name)) {
          if (actor[key] && actor[key].length) {
            for (const connection of actor[key]) {
              if (!connection.to_name || connection.to_name.length == 0) {
                continue
              }

              result.push({
                ...connection,
                type: key,
              })
            }
          }
        }

        return result
      },
      // Get edges that come from a given actor.
      getEdgesFromActor (actor, onlyVisibleActors = false) {
        const connections = this.getConnectionsFromActor(actor)
        const result = connections.reduce((acc, connection) => {
          const relationshipType = this.ecosystemRelationships.find(t => t.name === connection.type)
          const source = relationshipType.is_forward_connection ? actor.id : connection.to
          const target = relationshipType.is_forward_connection ? connection.to : actor.id
          const forwardType = relationshipType.is_forward_connection
            ? relationshipType.name
            : relationshipType.inverse_name
          const score = connection.score
          const comment = connection.comment
          const start = connection.start
          const end = connection.end
          const status = connection.status

          if (connection.tags && connection.tags.length > 0 && forwardType === 'sends') {
            const edges = []
            for (const tag of connection.tags) {
              edges.push({
                group: 'edges',
                data: {
                  id: `${source}${target}${forwardType}${tag}`,
                  source,
                  target,
                  score,
                  comment,
                  start,
                  end,
                  status,
                  type: 'sends ' + tag,
                },
              })
            }
            return acc.concat(edges)
          }
          acc.push({
            group: 'edges',
            data: {
              id: `${source}${target}${forwardType}`,
              source,
              target,
              score,
              comment,
              start,
              end,
              status,
              type: forwardType,
            },
          })
          return acc
        }, [])

        if (onlyVisibleActors) {
          const nodeIds = this.cy.json().elements.nodes.map(node => node.data.id)
          return result.filter(edge => {
            return nodeIds.some(id => edge.data.source === id) && nodeIds.some(id => edge.data.target === id)
          })
        } else {
          return result
        }
      },
      // Generate array of node styles for the graph view
      getNodeStyles () {
        var lookup = this.$store.state.config.legendMapping && this.$store.state.config.legendMapping[this.legendProperty]

        if (!lookup) {
          lookup = this.$store.state.filters.legendLookup
        } else {
          lookup = Object.keys(lookup).map((legend) => {
            return {
              label: legend,
              hex: this.$store.getters.hexColours[lookup[legend]],
            }
          })
        }

        var styles = Object.values(lookup).map((legend) => {
          return {
            selector: 'node[legend="legend_id_' + legend.label + '"]',
            style: {
              'border-color': legend.hex,
              'text-border-color': legend.hex,
            },
          }
        })

        return styles
      },
      // Generate array of edge styles for the graph view
      getEdgeStyles () {
        const getHexColor = (index) => {
          const hexColors = Object.values(this.$store.state.config.hexColours)
          if (hexColors && hexColors[index]) {
            return hexColors[index]
          }
        }

        return Object.values(this.ecosystemRelationships).reduce((acc, type, index) => {
          if (type.name === 'has_collaboration_with' || type.name === 'collaborates_with') {
            acc.push({
              selector: `edge[type="${type.name}"]`,
              style: {
                'line-color': getHexColor(index),
                'target-arrow-color': getHexColor(index),
                'source-arrow-color': getHexColor(index),
                'source-arrow-shape': 'triangle',
              },
            })
            return acc
          }

          if (type.name === 'sends' || type.name === 'receives') {
            if (this.ecosystemMetaDataLists.length > 0) {
              const sendsColours = this.ecosystemMetaDataLists[0].tagColours
              acc = acc.concat(Object.keys(sendsColours).map((type) => {
                return {
                  selector: `edge[type="sends ${type}"]`,
                  style: {
                    'line-color': sendsColours[type],
                    'target-arrow-color': sendsColours[type],
                  },
                }
              }))
            }

            acc.push({
              selector: `edge[type="${type.name}"]`,
              style: {
                'line-color': '#bbbbbb',
                'target-arrow-color': '#bbbbbb',
              },
            })
            return acc
          }

          acc.push({
            selector: `edge[type="${type.name}"]`,
            style: {
              'line-color': getHexColor(index),
              'target-arrow-color': getHexColor(index),
            },
          })
          return acc
        }, [])
      },
      // Fetch list of edges for a given list of actorIds. These edges are not yet
      // in the graph view and are only between nodes that are already in the graph view
      fetchConnectedActors (connectedActorIds) {
        const promises = []
        let connections = []

        const actorIdsToFetch = []

        for (const actorId of connectedActorIds) {
          // Don't fetch again if the actor is already cached
          const fetchedActor = this.actors.find(actor => actor.id === actorId)
          if (fetchedActor) {
            const newConnections = this.getEdgesFromActor(fetchedActor, true)
            connections = connections.concat(newConnections)
          } else {
            actorIdsToFetch.push(actorId)
            /* promises.push(fetchActor(actorId).then((actor) => {
              this.actors.push(actor)

              // We have the actor's logo now, so we force rerendering of the node
              // by calling lock and unlock.
              const node = this.cy.getElementById(actor.id);
              node.lock();
              node.unlock();

              let newConnections = this.getEdgesFromActor(actor, true);

              connections = connections.concat(newConnections);
            })); */
          }
        }

        promises.push(
          fetchConnectionActors(actorIdsToFetch)
            .then((actors) => {
              if (actors && actors.length) {
                for (var index = 0; index < actors.length; index++) {
                  var actor = actors[index]

                  this.actors.push(actor)

                  // We have the actor's logo now, so we force rerendering of the node
                  // by calling lock and unlock.
                  const node = this.cy.getElementById(actor.id)
                  node.lock()
                  node.unlock()

                  const newConnections = this.getEdgesFromActor(actor, true)

                  connections = connections.concat(newConnections)
                }
              }
            })
            .catch(error => {
              console.log(error)
            }),
        )

        return Promise.all(promises).then(() => {
          if (!this.mounted) return
          const cyEdges = this.cy.json().elements.edges || []
          const edges = [
            ...cyEdges,
            ...connections,
          ]

          return edges
        })
      },
      clickNode (actorId) {
        this.$router.push(`/actors/${actorId}#connections`)
      },
      removeRelation (relationType, id) {
        this.$emit('handleRemoveRelation', relationType, id)
      },
      setVisibility (connectionType, value) {
        if (value === false && !this.hiddenTypes.some(hiddenType => hiddenType === connectionType)) {
          this.hiddenTypes.push(connectionType)
        } else {
          this.hiddenTypes = this.hiddenTypes.filter(hiddenType => hiddenType !== connectionType)
        }

        this.applyFilters()
      },
      applyFilters () {
        if (this.filteredNodes) {
          this.filteredNodes.restore()
        }

        this.filteredEdges && this.filteredEdges.restore()

        if (this.hiddenTypes.length) {
          let edgeSelector = this.hiddenTypes.map(type => `edge[type="${type}"]`)

          if (edgeSelector.length > 1) {
            edgeSelector = edgeSelector.reduce((a, b) => `${a}, ${b}`)
          } else {
            edgeSelector = edgeSelector[0]
          }

          const elements = this.cy.elements(`${edgeSelector}`)
          elements.remove()

          const centralNode = this.cy.elements().nodes()[0]
          const orphans = this.cy.elements('node[type != "central"]').filter(element => {
            const aStar = this.cy.elements().aStar({
              root: element,
              goal: centralNode,
            })
            return !aStar.found
          })

          // Not only the edges that we need hide must be removed, but also the edges that are no longer displayed due
          // to the removed nodes will be removed. We need to keep track of these edges as well if we want to restore everything properly
          var otherEdges = orphans.connectedEdges()

          orphans.remove()

          this.filteredNodes = orphans
          this.filteredEdges = elements.union(otherEdges)
        } else {
          this.filteredNodes = null
          this.filteredEdges = null
        }

        this.layout = this.cy.layout(layoutOptions).run()
      },
      // Count connections on the graph view and update the sidebar accordingly
      updateSidebarData () {
        const cyEdges = this.cy.json().elements.edges
        const edges = cyEdges ? cyEdges.map(edge => edge.data) : []
        if (this.filteredEdges) {
          this.filteredEdges.forEach(edge => {
            edges.push(edge.data())
          })
        }

        const connectionsByType = _groupBy(edges, 'type')
        this.connectionsByType = Object.keys(connectionsByType).reduce(
          (list, type) => {
            let label

            if (type.startsWith('sends')) {
              label = type
            } else {
              label = this.ecosystemRelationships.find(r => r.name === type).label
            }

            list.push({
              type: type,
              label: label,
              connections: connectionsByType[type],
            })

            return list
          },
          [],
        )
      },
    },
    mounted () {
      this.mounted = true

      // Only init cytoscape when we get the full connections (sometimes we get an array of simplified connections)
      if (this.connections[0] && this.connections[0].name !== undefined) {
        if (this.fetchingMetaDataLists) {
          this.metaDataListsPromise.then(() => {
            this.initCytoscape()
          })
        } else {
          this.initCytoscape()
        }
      }

      // Zoom in with ony command or control pressed
      window.addEventListener('keydown', (e) => {
        if (e.key == 'Control' || [17, 91, 93, 224].includes(e.keyCode) && this.cy) {
          this.cy.userZoomingEnabled(true)
        }
      })

      window.addEventListener('keyup', (e) => {
        if (e.key == 'Control' || [17, 91, 93, 224].includes(e.keyCode) && this.cy) {
          this.cy.userZoomingEnabled(false)
        }
      })
    },
    components: {
      GraphViewConnections,
      DsButton,
    },
    mixins: [CompanyMixin, FiltersMixin],
    watch: {
      actor () {
        this.isLoading = false
        this.isGraphLoading = false
      },
      connections () {
        // Clean up any saved reference to the nodes and edges of the previous state
        if (this.filteredNodes) {
          this.filteredNodes.remove()
        }

        if (this.filteredEdges) {
          this.filteredEdges.remove()
        }

        this.actors = []
        this.hiddenTypes = []

        this.filteredNodes = null
        this.filteredEdges = null

        // Then redraw the graph with the new data
        // Only update the graph view when we get the full connections array, not the simplified version
        if (this.connections[0] && this.connections[0].name !== undefined) {
          if (this.cy) {
            this.cy.json({ elements: this.getInitialGraphData() })
            this.updateSidebarData()
            this.applyFilters()

            this.fetchConnectedActors(this.connections.map(c => c.id)).then((connections) => {
              this.cy.add(connections)
              this.updateSidebarData()
              this.applyFilters()
            })
          } else {
            if (this.cy) {
              this.cy.destroy()
            }

            this.initCytoscape()
          }
        }
      },
    },
  }
</script>

<style lang="scss">
  .graph-view-container {
    border: 1px solid #ccc;
    min-height: 90vh;
    position: relative;
    width: 100%;

    .graph-view {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .graph-controls {
      align-items: center;
      display: flex;
      left: 10px;
      position: absolute;
      top: 10px;
      z-index: 999;

      button {
        background-color: #fff;
        border: 1px solid #CCCCCC;
        color: #A5A5A5;

        .svg-icon path {
          fill: #A5A5A5;
        }
      }
    }

    .zoom-instructions {
      display: flex;
      position: absolute;
      background-color: white;
      top: 10px;
      z-index: 999;
      right: 10px;
      word-break: break-word;
      width: 190px;
      font-size: 12px;
      border: 1px solid #CECECE;
      height: 60px;
      padding: 0.5rem;
    }

    .zoom-controls {
      top: 12px;
      display: flex;
      background-color: white;
      flex-direction: column;
      position: absolute;
      right: 210px;
      z-index: 1;

      .button {
        align-items: center;
        display: flex;
        font-size: 24px;
        font-weight: 500;
        height: 30px;
        justify-content: center;
        line-height: 0;
        margin-right: 0;
        margin-top: -1px;
        padding: 0;
        width: 30px;
      }
    }

    .loading-indicator {
      background-color: #666;
      color: white;
      padding: 6px 12px;
    }
  }
</style>
