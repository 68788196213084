<template>
  <div style="margin-bottom: 3rem;">
    <div v-for="(templateId, templateName) in customEmails">
      <form-group :label="humanize(templateName)" v-if="canDisplayEmail(templateName)">
        <ds-input v-model="customEmails[templateName]" />
      </form-group>
    </div>
  </div>
</template>

<script>
  import DsInput from '../Form/DsInput.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'custom-emails',
    methods: {
      canDisplayEmail (templateName) {
        if (['trial_will_end', 'payment_action_required', 'subscription_cancelled'].includes(templateName )) {
          return this.$store.getters.hasAccessToEcosystemMemberPackages
        }

        return true
      },
      // Humanize the email template type
      humanize (label) {
        switch (label) {
          case 'curation_accepted':
            return 'Actor profile curation accepted'
          case 'curation_denied':
            return 'Actor profile curation denied'
          case 'accepted_edited':
            return 'Actor profile curation accepted, but edited'
          case 'contribute_invitation':
            return 'Contribution invitation - new user'
          case 'contribute_invitation_existing_user':
            return 'Contribution invitation - existing user'
          case 'claim_company':
            return 'Claim company'
          case 'password_reset':
            return 'Password reset'
          case 'confirm_email':
            return 'Email confirmation'
          case 'challenge_denied':
            return 'Challenge curation denied'
          case 'challenge_accepted':
            return 'Challenge curation accepted'
          case 'challenge_invitation':
            return 'Challenge invitation'
          case 'content_digest':
            return 'Content Digest'
          case 'weekly_digest':
            return 'Weekly Digest'
          case 'new_user_invitation':
            return 'Invitation for new users'
          case 'existing_user_invitation':
            return 'Invitation for existing users - users that were already registered on the DataScouts platform'
          case 'message_sent_from_user_no_claim':
            return 'User received a message from a different user, which has no claimed actor'
          case 'message_sent_from_user_with_claim':
            return 'User received a message from a different user, which has a claimed actor'
          case 'trial_will_end':
            return 'The subscription trial will soon end'
          case 'payment_action_required':
            return 'Payment action required'
          case 'subscription_cancelled':
            return 'Subscription cancelled'
          case 'report_submission_successful':
            return 'Report submission successful'
          case 'new_conversation_comment':
            return 'New conversation message'
        }
      },
    },
    computed: {
      form () {
        return this.$parent.$parent.form
      },
      customEmails () {
        return this.form.customEmails
      }
    },
    components: {
      DsInput,
    },
  })
</script>
