<template>
  <div class="actor-edit side-panel--wide">
    <div class="actor-edit__header" ref="header">
      <div class="actor-edit__header__upper-container">
        <h2 class="actor-edit__header__info">
          <span class="actor-edit__header__title">{{ $t('edit_actor_title') }}: {{ originalActor.name }}</span>
        </h2>
        <div class="actor-edit__header__actions">
          <!-- Add the delete button if the user is in a actor profile -->
          <ds-button
            v-if="$route.params.id && canDeleteActorProfile"
            variant="minimal"
            icon="trash"
            size="extra-small"
            class="side-panel__delete-button"
            style="right: 4.5em; top: 1.3em;"
            @click="showDeleteConfirmationModal(originalActor)"
          />
          <ds-button icon="remove" variant="minimal" @click="showCancelActorEditConfirmationModal"/>
        </div>
      </div>
      <div class="form-group__error" v-if="Object.keys(errors).length" style="font-size: 14px">
        There {{ Object.keys(errors).length === 1 ? 'was 1 error' : 'were ' + Object.keys(errors).length + ' errors' }} when saving:
        <div v-for="error in errors">
          {{ Array.isArray(error) ? error[0] : error }}
        </div>
        <div v-if="isMergingAvailable">
          <a href="#" @click="openDuplicatesPanel" style="color: #FD916D;">
            Click here to merge with existing actors.
          </a>
        </div>
      </div>
    </div>
    <div class="has-padding actor-edit__hud_container" v-if="isPublic" ref="hudContainer">
      <div class="actor-edit__hud-public">
        <div></div>
        <span>{{ $t('edit_actor_published') }}</span>
      </div>
      <div class="actor-edit__hud-private">
        <div></div>
        <span>{{ $t('edit_actor_internal') }}</span>
      </div>
    </div>
    <div class="actor-edit__content" ref="container">
      <form v-if="modifiedActor" @submit.prevent="saveActor">
        <form-section id="main-details">
          <div class="row">
            <form-group
              class="col-xs-12 col-sm-6" :label="labelName" aria-autocomplete="none"
              :class="{'actor-edit__private-field': isPrivateField('name')}"
            >
              <ds-input
                name="actor-name" v-model="modifiedActor.name"
                :errors="errors.name" no-autocomplete
              />
            </form-group>
            <template v-if="modifiedActor.actor_type === 'LegalEntity'">
              <form-group class="col-xs-12 col-sm-2" label="Logo" :class="{'actor-edit__private-field': isPrivateField('logo')}">
                <image-input
                  v-model="modifiedActor.logo" width="100" height="100"
                  :allow-remove="true" @remove="modifiedActor.logo = ''"
                />
              </form-group>
              <div class="col-xs-12 col-sm-4" style="font-size: 12px; color: #555; margin-top: 18px">
                {{ $t('actor_edit_logo_info') }}
              </div>
            </template>
          </div>
          <div class="row" v-if="isRelevantForActor('product', modifiedActor)">
            <form-group class="col-xs-12" :label="$t('edit_actor_product')" :class="{'actor-edit__private-field': isPrivateField('product')}">
              <ds-input name="actor-product" v-model="modifiedActor.product" :errors="errors.product"/>
            </form-group>
          </div>
          <div class="row">
            <form-group label="Type" class="col-xs-12 col-sm-6" :errors="errors.actor_type" :class="{'actor-edit__private-field': isPrivateField('actor_type')}">
              <dropdown placeholder="Type" :options="actorTypeOptions" v-model="modifiedActor.actor_type" @update:modelValue="onUpdateActorType"/>
            </form-group>
            <form-group :label="categoryLabel" class="col-xs-12 col-sm-6" :key="modifiedActor.actor_type" :errors="errors.category" v-if="isEcosystemMember">
              <dropdown
                placeholder="" :valueIsOption="true" :options="categoryOptions" v-model="modifiedActor.category" search
                :class="{'actor-edit__private-field': isPrivateField('category')}"
              />
            </form-group>
            <form-group label="Category" class="col-xs-12 col-sm-6" :key="modifiedActor.actor_type" :errors="errors.category" v-else>
              <div v-if="show.addCategory" class="actor-edit__new-category-container">
                <ds-input
                  name="actor-new-taxonomy" placeholder="New category" v-model="newCategoryName" @keypress.enter="addNewCategory" ref="newCategoryNameInput"
                  class="col-xs-8"
                />
                <ds-button variant="minimal" icon="undo" @click="show.addCategory = false" class="col-xs-4"/>
              </div>
              <dropdown
                placeholder="Category"
                :options="categoryOptions"
                v-model="modifiedActor.category"
                search
                :noResultsHandlerEnabled="true"
                :noResultMessage="noResultMessageCategory"
                @addNewItem="startAddingCategory" :class="{'actor-edit__private-field': isPrivateField('category')}"
                :suggestions="categorySuggestions"
                @suggestion:accept="value => decideSuggestion('category', value, 'accepted')"
                @suggestion:decline="value => decideSuggestion('category', value, 'denied')"
                :value-is-option="true"
                v-else
              />
            </form-group>
          </div>
        </form-section>

        <div class="row">
          <taxonomy-quick-edit
            :modified-actor="modifiedActor"
          />
        </div>

        <div class="collapsable-tab" v-for="tab in tabs" :class="{'collapsable-tab-open-state' : activeTabs.includes(tab)}">
          <table
            class="collapsable-tab__tab-content-container" :class="{'collapsable-tab__tab-content-container-open-state' : activeTabs.includes(tab)}"
            @click="setActiveTabs(tab)" :ref="'tab_' + tab"
            v-if="tabIsVisible(tab)"
          >
            <tr>
              <td class="collapsable-tab__title">{{ tabDisplayName(tab) }}</td>
              <td class="collapsable-tab__description" v-if="tab !== 'Connections'">{{ $t('edit_actor_completeness') }} {{ tabCompletenessPercentage(tab) }}</td>
              <td class="collapsable-tab__toggle-button">
                <icon :name="activeTabs.includes(tab) ? 'chevron-up' : 'chevron-down'"/>
              </td>
            </tr>
          </table>
          <actor-edit-description :modified-actor="modifiedActor" :errors="errors" v-if="activeTabs.includes(tab) && tab === 'Description'"/>
          <actor-edit-skills-and-expertise
            :modified-actor="modifiedActor" :errors="errors" v-if="activeTabs.includes(tab) && tab === 'Skills & Expertise' && modifiedActor.actor_type === 'Person'"
          />
          <actor-edit-digital-presence
            :modified-actor="modifiedActor" :errors="errors" :show="show" v-if="activeTabs.includes(tab) && tab === 'Digital Presence'"
            @deleteAll="onDigitalPresenceDeleteAll($event)"
          />

          <actor-edit-activities
            :modified-actor="modifiedActor"
            :errors="errors"
            :all-industry-suggestions="allIndustrySuggestions"
            :pending-suggestions-for-property="pendingSuggestionsForProperty"
            :taxonomy-value-for-suggestion-value="taxonomyValueForSuggestionValue"
            :decide-suggestion="decideSuggestion"
            :show="show"
            v-if="activeTabs.includes(tab) && tab === 'Activities'"
          />

          <actor-edit-contact-details
            :modified-actor="modifiedActor"
            @updateContactableContributors="updateContactableContributors"
            :actor-contributors="actorContributors"
            :errors="errors"
            :jurisdiction-options="jurisdictionOptions"
            :show="show"
            v-if="activeTabs.includes(tab) && tab === 'Contact Details'"
          />
          <actor-edit-administration
            :modified-actor="modifiedActor"
            :errors="errors"
            :jurisdiction-options="jurisdictionOptions"
            :show="show"
            v-if="activeTabs.includes(tab) && tab === 'Administration'"
          />
          <actor-edit-financials :modified-actor="modifiedActor" :errors="errors" :reset-errors="resetErrors" v-if="activeTabs.includes(tab) && tab === 'Financials'"/>
          <actor-edit-segmentation
            :modified-actor="modifiedActor"
            :errors="errors"
            :show="show"
            :user-can-edit-portfolio="userCanEditPortfolio" v-if="activeTabs.includes(tab) && tab === 'Segmentation'"
          />
        </div>
      </form>
      <action-bar editing="1" class="side-panel__portfolio-bottom-section" v-if="isEditing || hasSuggestionDecisions">
        <ds-button variant="secondary" :label="isMember && modifiedActor && modifiedActor.draft ? 'Save and approve' : 'Save'" :disabled="isSaving"
          :icon="isSaving ? 'spinner' : ''" @click="saveActor"/>
      </action-bar>
    </div>
  </div>
</template>

<script>
  import { cloneDeep, get, isEmpty, xor } from 'lodash/lodash'
  import { Categories } from '@/api/taxonomies.js'
  import { excludeActorFromPortfolio, includeActorToPortfolio } from '@/api/portfolios.js'
  import { fetchActor, fetchContributors, removeActor, updateActor, updateContactableContributors } from '@/api/actors.js'
  import { Suggestions } from '@/api/heartbeat.js'

  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as TAXONOMY_ACTION_TYPES, MUTATION_TYPES as TAXONOMY_MUTATION_TYPES } from '../../store/modules/taxonomies'

  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import DsInput from '../../components/Form/DsInput.vue'
  import FormGroup from '../../components/Form/FormGroup.vue'
  import FormSection from '../../components/Form/FormSection.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import TaxonomyQuickEdit from './ActorEditSections/TaxonomyQuickEdit.vue'

  import { COUNTRIES } from '../../constants/countries'
  import MODAL_IDS from '../../constants/modal-ids'

  import { emptyActor } from '../../util/actor'
  import CompanyMixin from '../../util/CompanyMixin'
  import ConfigMixin from '../../util/ConfigMixin'
  import TagsMixin from '../../util/TagsMixin'

  import diffObjects from '../../util/diff-objects'
  import { inert, isRelevantForActor, unArrayify } from '../../util/helpers'

  import { trackHeapEvent } from '../../util/analytics'
  import { SiteTemplate } from '../../store/modules/config'
  import {
    ActorEditActivities,
    ActorEditAdministration,
    ActorEditContactDetails,
    ActorEditDescription,
    ActorEditDigitalPresence,
    ActorEditFinancials,
    ActorEditProductServiceOffering,
    ActorEditSegmentation,
    ActorEditSkillsAndExpertise,
  } from './ActorEditSections'
  import ImageInput from '../Form/ImageInput.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  export default {
    data () {
      return {
        errors: {},
        isEditing: null,
        isSaving: false,
        actorContributors: [],
        originalActorContributors: [],
        originalActor: {
          tags: [],
          seo_keywords: [],
          description: '',
          activities_description: '',
        },
        modifiedActor: {
          tags: [],
          seo_keywords: [],
          description: '',
          activities_description: '',
          additional_information: '',
        },
        activeTabs: 'Description',
        show: {
          addOffice: false,
          addCrunchBase: false,
          addYoutube: false,
          addVimeo: false,
          addKennisWest: false,
          files: true,
          addCategory: false,
          addActivity: false,
          addTechnology: false,
          addIndustry: false,
          addOffering: false,
          editOffering: false,
          addFounder: false,
        },
        fieldList: {},
        baseFieldList: {
          description: {
            displayName: this.$t('edit_actor_segment_description'),
            name: 'description',
            normalFields: ['activities_description', 'description', 'tags'],
            specialFields: [],
          },
          skillsExpertise: { displayName: this.$t('edit_actor_segment_skills_and_expertise'), name: 'skills_expertise', normalFields: [], specialFields: [] },
          digitalPresence: {
            displayName: this.$t('edit_actor_segment_digital_presence'),
            name: 'digital_presence',
            normalFields: ['url', 'other_urls', 'twitter', 'linkedin', 'facebook', 'reddit', 'instagram', 'vimeo', 'youtube', 'crunchbase', 'kenniswest', 'wikipedia', 'rss_feed'],
            specialFields: [],
          },
          activities: { displayName: this.$t('edit_actor_segment_activities'), name: 'activities', normalFields: ['sustainability_goal', 'market'], specialFields: [] },
          contactDetails: {
            displayName: this.$t('edit_actor_segment_contact'),
            name: 'contact_details',
            normalFields: ['phone', 'email'],
            specialFields: ['street', 'number', 'zip', 'city', 'country_code'],
          },
          financials: { displayName: 'Financials', name: 'financials', normalFields: ['funding_structure', 'stock_symbol'], specialFields: [] },
          administration: { displayName: 'Administration', name: 'administration', normalFields: ['founding_date'], specialFields: [] },
          segmentation: { displayName: 'Segmentation', name: 'segmentation', normalFields: ['portfolios'], specialFields: [] },
        },
        allPortfolios: [],
        noResultMessageCategory: {
          text: 'Other',
          smallText: 'Create new category',
        },
        suggestions: [],
        suggestionDecisions: {}, // id => accepted/denied
        hasManualSuggestionDecisions: false,
        duplicateInput: {},
        newCategoryName: '',
        tabCompletenessInterval: null,
      }
    },
    props: {
      actorId: String,
      openTab: String,
    },
    computed: {
      categorySuggestions () {
        if (this.modifiedActor.category) {
          return []
        }

        return this.pendingSuggestionsForProperty('category')
          .map(v => this.taxonomyValueForSuggestionValue(v.value[0], 'categories'))
      },
      actorTypeOptions () {
        return this.$store.getters.viewActorTypes.map(actorType => ({
          value: actorType,
          label: this.actorTypeMapping[actorType] || actorType,
          disabled: this.$store.getters.isActor &&
              !this.$store.getters.claimableActorTypes.includes(actorType) &&
              actorType !== this.modifiedActor.actor_type,
        }),
        ).filter(type => {
          return !type.disabled
        })
      },
      actorTypeMapping () {
        return {
          LegalEntity: this.$t('edit_actor_type_legal_entity_label'),
          Person: this.$t('edit_actor_type_person_label'),
          Product: this.productLabel,
        }
      },
      publicFields () {
        return this.$store.getters.publicFields
      },
      industries () {
        return this.$store.state.taxonomies.industries
      },
      membershipOptions () {
        return this.membershipValuesAndLabels
      },
      categories () {
        return this.$store.getters.categoryLabels
      },
      categoryOptions () {
        const list = this.categoryValuesAndLabelsPerType[this.modifiedActor.actor_type] || []

        let categories = list.slice(0)

        categories = categories.sort((a, b) => {
          return a.label > b.label
        })

        return categories.map(item => ({
          value: item.value,
          label: item.label,
        }))
      },
      tabs () {
        let tabs = ['Description', 'Activities', 'Digital Presence', 'Contact Details', 'Administration', 'Financials', 'Segmentation']

        if (this.modifiedActor.actor_type === 'Person') {
          tabs = ['Description', 'Skills & Expertise', 'Activities', 'Digital Presence', 'Contact Details', 'Administration', 'Segmentation']
        } else if (this.modifiedActor.actor_type === 'Product') {
          tabs = ['Description', 'Activities', 'Digital Presence', 'Contact Details', 'Administration', 'Segmentation']
        }

        return tabs
      },
      isSimplifiedTemplateUsed () {
        return this.$store.getters.visitorSiteTemplate === SiteTemplate.SIMPLIFIED ||
          this.$store.getters.visitorSiteTemplate === SiteTemplate.NEW_SIMPLIFIED ||
          this.$store.getters.ecosystemMemberSiteTemplate === SiteTemplate.NEW_SIMPLIFIED ||
          this.$store.getters.ecosystemMemberSiteTemplate === SiteTemplate.SIMPLIFIED
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled
      },
      portfolioOptions () {
        return this.allPortfolios
          .filter(i => !i.virtual && !i.is_immutable)
          .map(i => {
            return { value: i.value, label: i.label }
          })
      },
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK').sort()
      },
      labelName () {
        return this.$t('edit_actor_name')
      },
      canDeleteActorProfile () {
        return this.isOwner || this.isTeamMember || this.userIsActorOwner
      },
      // This computed property is a proxy in orde to use the CompanyMixin
      company () {
        return this.originalActor
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      isMember () {
        return this.$store.getters.isMember
      },
      categoryLabel () {
        return this.getTaxonomyAlias('category') || 'Category'
      },
      allIndustrySuggestions () {
        return this.suggestions.filter(s => s.property === 'industries').map(s => unArrayify(s.value)) || []
      },
      hasSuggestionDecisions () {
        for (const id in this.suggestionDecisions) {
          if (this.suggestionDecisions[id] !== null) {
            return true
          }
        }

        return false
      },
      isMergingAvailable () {
        return this.isMember && Object.keys(this.duplicateInput).length > 0
      },
    },
    methods: {
      fetchContributors () {
        fetchContributors(this.actorId, { includeOwners: true })
          .then(list => {
            this.actorContributors = list
              .map((contributor) => {
                return {
                  value: contributor.user_id,
                  label: contributor.user_name,
                  allow_conversation: contributor.allow_conversation,
                }
              })
            this.originalActorContributors = inert(this.actorContributors)
          })
      },
      updateContactableContributors (newContactableContributors) {
        const contactableUserIds = newContactableContributors.map(c => c.value || c) || []

        this.actorContributors = this.actorContributors.map(contributor => {
          contributor.allow_conversation = contactableUserIds.includes(contributor.value)

          return contributor
        })
      },
      resetFieldList () {
        this.fieldList = {}
        this.fieldList = cloneDeep(this.baseFieldList)

        if (this.isPublic) {
          this.fieldList.description.specialFields = []
        }
        if (this.isOwner || this.isMember) {
          this.fieldList.description.normalFields.push('private_tags')
        }
        if ((this.isMember || ['wear', 'wearables'].includes(this.$store.getters.currentEcosystem.name)) && this.membershipOptions.length) {
          this.fieldList.segmentation.normalFields.push('membership')
        }
        if (isRelevantForActor('company_number', this.modifiedActor) || isRelevantForActor('founding_date', this.modifiedActor)) {
          this.fieldList.segmentation.normalFields.push('maturity')
        }

        switch (this.modifiedActor.actor_type) {
          case 'LegalEntity':
            this.fieldList.skillsExpertise.normalFields = ['expertises', 'motivations']
            this.fieldList.segmentation.normalFields.push('stage')
            this.fieldList.segmentation.normalFields.push('funnel_stage')
            this.fieldList.administration.normalFields = this.fieldList.administration.normalFields.concat(['jurisdiction', 'company_number', 'vat_number', 'employees'])
            break
          case 'Community':
            this.fieldList.skillsExpertise.normalFields = ['expertises', 'motivations']
            if (this.businessAspectValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('business_aspects')
            if (this.processStepValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('process_steps')
            if (this.readinessLevelValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('readiness_levels')
            this.fieldList.segmentation.normalFields.push('stage')
            this.fieldList.segmentation.normalFields.push('funnel_stage')

            this.fieldList.administration.normalFields = this.fieldList.administration.normalFields.concat(['jurisdiction', 'company_number', 'vat_number', 'employees'])
            break
          case 'Person':
            this.fieldList.skillsExpertise.normalFields = ['employment.seniority', 'employment.title', 'employment.role', 'motivations', 'expertises']
            this.fieldList.segmentation.normalFields.push('stage')
            this.fieldList.segmentation.normalFields.push('funnel_stage')
            break
          case 'Product':
            if (this.businessAspectValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('business_aspects')
            if (this.processStepValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('process_steps')
            if (this.readinessLevelValuesAndLabels.length > 0) this.fieldList.skillsExpertise.normalFields.push('readiness_levels')
            break
        }
        if (this.activityValuesAndLabels && this.activityValuesAndLabels.length > 0 && isRelevantForActor('activities', this.modifiedActor)) {
          this.fieldList.activities.normalFields.push('activities')
        }
        if (isRelevantForActor('domains', this.modifiedActor)) {
          this.fieldList.activities.normalFields.push('domains')
        }
        if (isRelevantForActor('product_features_a', this.modifiedActor) && this.$store.state.taxonomies.productFeaturesA.length > 0) {
          this.fieldList.activities.normalFields.push('product_features_a')
        }
        if (isRelevantForActor('product_features_b', this.modifiedActor) && this.$store.state.taxonomies.productFeaturesB.length > 0) {
          this.fieldList.activities.normalFields.push('product_features_b')
        }
        if (isRelevantForActor('product_features_c', this.modifiedActor) && this.$store.state.taxonomies.productFeaturesC.length > 0) {
          this.fieldList.activities.normalFields.push('product_features_c')
        }
        if (isRelevantForActor('additional_information', this.modifiedActor)) {
          this.fieldList.description.normalFields.push('additional_information')
        }
        if (this.industries) {
          this.fieldList.activities.normalFields.push('industries')
        }
        if (this.technologyValuesAndLabels) {
          this.fieldList.activities.normalFields.push('technology')
        }
        if (this.isRelevantForActor('type', this.modifiedActor)) {
          this.fieldList.activities.specialFields.push('type')
        }
        if (this.isRelevantForActor('not_for_profit', this.modifiedActor)) {
          this.fieldList.activities.specialFields.push('not_for_profit')
        }
      },
      clearFields () {
        for (const fieldName in this.fieldList) {
          if (this.fieldList.hasOwnProperty(fieldName)) {
            this.fieldList[fieldName].count = 0
          }
        }
      },
      calculateCompletionPerSection () {
        for (const fieldName in this.fieldList) {
          if (this.fieldList.hasOwnProperty(fieldName)) {
            const field = this.fieldList[fieldName]
            for (const normalField of field.normalFields) {
              switch (typeof get(this.modifiedActor, normalField)) {
                case 'string':
                  if (get(this.modifiedActor, normalField) !== undefined && get(this.modifiedActor, normalField).length > 0 && get(this.modifiedActor, normalField) !== '<p></p>') {
                    field.count += 1
                  }
                  break
                case 'object':
                  if (get(this.modifiedActor, normalField) !== null && get(this.modifiedActor, normalField).length > 0) {
                    field.count += 1
                  } else if (get(this.modifiedActor, normalField) !== null && !Array.isArray(get(this.modifiedActor, normalField))) {
                    field.count += 1
                  }
                  break
                case 'number':
                case 'boolean':
                  if (get(this.modifiedActor, normalField) !== undefined) {
                    field.count += 1
                  }
                  break
              }
            }
            for (const specialField of field.specialFields) {
              switch (specialField) {
                case 'not_for_profit':
                case 'type':
                  if (this.modifiedActor[specialField] !== null) {
                    if ((this.modifiedActor.type && this.modifiedActor.type !== 'other' && this.modifiedActor.type.length > 0) || (this.modifiedActor.not_for_profit === true || this.modifiedActor.not_for_profit === true)) {
                      field.count += 1
                    }
                  }
                  break
                case 'street':
                case 'number':
                case 'zip':
                case 'city':
                case 'country_code':
                  if (this.modifiedActor.address && this.modifiedActor.address[specialField] !== null && this.modifiedActor.address[specialField].length > 0) {
                    field.count += 1
                  }
                  break
              }
            }
          }
        }
      },
      tabDisplayName (tab) {
        switch (tab) {
          case 'Description':
            return this.$t('edit_actor_segment_description')
          case 'Skills & Expertise':
            return this.$t('edit_actor_segment_skills_and_expertise')
          case 'Activities':
            return this.$t('edit_actor_segment_activities')
          case 'Digital Presence':
            return this.$t('edit_actor_segment_digital_presence')
          case 'Contact Details':
            return this.$t('edit_actor_segment_contact')
          case 'Segmentation':
            return this.$t('actor_edit_segmentation')
        }
        return tab
      },
      isRelevantForActor,
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
      openDuplicatesPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-duplicates-panel',
          metaData:
            { duplicateInput: this.duplicateInput, duplicatesAreVirtual: true },
        })
      },
      tabIsVisible (tabName) {
        switch (tabName) {
          case 'Administration':
            return this.isRelevantForActor('company_number', this.modifiedActor) || this.isRelevantForActor('founding_date', this.modifiedActor)
          case 'Financials':
            return true
          case 'Skills & Expertise':
            return !window.user.onboardingComplete
          default:
            return true
        }
      },
      showCancelActorEditConfirmationModal () {
        if (this.actorDiff() || this.hasManualSuggestionDecisions) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CANCEL_ACTOR_EDIT_CONFIRMATION)
        } else {
          this.back()
        }
      },
      back () {
        this.hidePreview()
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CANCEL_ACTOR_EDIT_CONFIRMATION)
      },
      async saveActor () {
        this.isSaving = true

        const contributorChanges = this.contributorsDiff()

        if (contributorChanges) {
          await updateContactableContributors(this.actorId, this.actorContributors.filter(c => c.allow_conversation).map(c => c.value))
        }

        const changes = this.actorDiff() || {}
        this.resetErrors()

        if (!changes && !this.hasSuggestionDecisions) {
          this.isSaving = false

          return
        }

        for (const suggestion of this.suggestions) {
          if (this.suggestionDecisions[suggestion.id] === null) {
            continue
          }

          switch (suggestion.property) {
            case 'industries':
            case 'activities':
            case 'technology':
              let property = suggestion.property

              if (suggestion.property === 'technology') {
                property = 'technologies'
              }

              const simpleTaxonomyValue = this.taxonomyValueForSuggestionValue(unArrayify(suggestion.value), property)

              if (!(this.modifiedActor[suggestion.property] || []).map(taxonomy => taxonomy.value).includes(simpleTaxonomyValue.value)) {
                this.suggestionDecisions[suggestion.id] = 'denied'
              }

              break
            case 'tags':
              if (!this.modifiedActor[suggestion.property].includes(unArrayify(suggestion.value))) {
                this.suggestionDecisions[suggestion.id] = 'denied'
              }
              break
          }
        }
        if (changes.funding_rounds || changes.exit_round) {
          changes.invested_by = this.modifiedActor.invested_by
        }
        if (this.modifiedActor.draft) {
          changes.draft = 0
        }

        if (changes.product_features_a) {
          changes.product_features_a = this.transformProductFeatures(changes.product_features_a)
        }
        if (changes.product_features_b) {
          changes.product_features_b = this.transformProductFeatures(changes.product_features_b)
        }
        if (changes.product_features_c) {
          changes.product_features_c = this.transformProductFeatures(changes.product_features_c)
        }
        if (changes.industries) {
          changes.industries = this.transformIndustries(changes.industries)
        }

        if ('membership' in changes) {
          changes.membership = changes.membership || ''
        }
        if ('stage' in changes) {
          changes.stage = changes.stage || ''
        }
        if ('funnel_stage' in changes) {
          changes.funnel_stage = changes.funnel_stage || ''
        }

        // Update the portfolios if portfolios have changed, or all portfolios have been removed
        if (changes.portfolios && (changes.portfolios.length > 0 || (this.modifiedActor.portfolios.length === 0 && changes.portfolios.length === 0))) {
          changes.portfolios.forEach(portfolio => {
            // If the any of the IDs of the new portfolios array does not exists in the original actors portfolio IDs, the the actor is added to that portfolio
            if (!this.originalActor.portfolios.map(portfolio => portfolio.id).includes(portfolio.id)) {
              this.includeActorToPortfolio({
                portfolioId: portfolio.id,
                data: { actorId: this.actorId },
              })
            }
          })

          this.originalActor.portfolios.forEach(portfolio => {
            // If any of the original actors portfolio IDs is not included in the new array of portfolios, then the actor is excluded from that portfolio
            if (!changes.portfolios.map(portfolio => portfolio.id).includes(portfolio.id)) {
              this.excludeActorFromPortfolio({
                portfolioId: portfolio.id,
                data:
                  { actorId: this.actorId },
              })
            }
          })
        }

        // If we're in the directory make sure to reload the data in the background
        if (this.$route.name === 'explore' || (this.$route.path.startsWith('/actors/') && !this.$route.params.id)) {
          updateActor({ id: this.actorId, data: changes })
            .then(() => this.saveSuggestionDecisions())
            .then(() => {
              trackHeapEvent('actorDetail.edit', { changes })

              // Reset the duplicate values object
              this.duplicateInput = {}

              // Fetch the data after a couple of seconds, storing it in MySQL and indexing it in ElasticSearch shouldn't take up more time than that
              setTimeout(() => {
                const listFilters = inert(this.$store.getters.listFilterObject)
                this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

                const filters = this.$store.getters.mapFilterObject

                if (filters.tl) {
                  this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
                }
              }, 2000)

              this.isSaving = false
              this.hidePreview()
            })
            .catch(errors => {
              this.isSaving = false
              this.errors = errors || {}

              // Check if there are errors that indicate a duplicate exists (company number / url / vat number)
              Object.keys(errors).forEach(key => {
                if (key === 'company_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                } else if (key === 'url' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                } else if (key === 'vat_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                }
              })
            })
        } else if (this.$route.path.startsWith('/actors/') && this.$route.params.id && this.actorId !== this.$route.params.id) {
          // If we're updating actor whilst being on a different actor detail page (i.e. a user clicks on a product listed in the "offering" tab of a LegalEntity actor
          // Then update the actor and request the proper reload of the actor of the current detail page, not the actor that was updated
          updateActor({ id: this.actorId, data: changes })
            .then(() => this.saveSuggestionDecisions())
            .then(() => {
              trackHeapEvent('actorDetail.edit', { changes })

              // Fetch the actor the user is viewing
              this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)

              // Reset the duplicate values object
              this.duplicateInput = {}

              // Fetch the data after a couple of seconds, storing it in MySQL and indexing it in ElasticSearch shouldn't take up more time than that
              setTimeout(() => {
                const listFilters = inert(this.$store.getters.listFilterObject)
                this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

                const filters = this.$store.getters.mapFilterObject

                if (filters.tl) {
                  this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
                }
              }, 2000)

              this.isSaving = false
              this.hidePreview()
            })
            .catch(errors => {
              this.isSaving = false
              this.errors = errors || {}

              // Check if there are errors that indicate a duplicate exists (company number / url / vat number)
              Object.keys(errors).forEach(key => {
                if (key === 'company_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                } else if (key === 'url' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                } else if (key === 'vat_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                }
              })
            })
        } else {
          this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.actorId, data: changes })
            .then(() => this.saveSuggestionDecisions())
            .then(() => {
              // Reset the duplicate values object
              this.duplicateInput = {}
              trackHeapEvent('actorDetail.edit', { changes })
              this.$bus.emit('actorUpdate', this.actorId)

              if (this.$root.curating || (this.modifiedActor.draft && this.isMember)) {
                this.hidePreview()
              } else {
                if (this.$route.path.startsWith('/actors/') && this.$route.params.id) {
                  this.hidePreview()
                } else {
                  this.back()
                }
              }
            })
            .catch(errors => {
              this.errors = errors || {}

              // Check if there are errors that indicate a duplicate exists (company number / url / vat number)
              Object.keys(errors).forEach(key => {
                if (key === 'company_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                } else if (key === 'url' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                } else if (key === 'vat_number' && errors[key][1]) {
                  this.duplicateInput[key] = [errors[key][1]]
                  delete errors[key][1]
                }
              })
            })
            .then(() => {
              this.isSaving = false
            })
        }
      },
      saveSuggestionDecisions () {
        const data = Object.keys(this.suggestionDecisions)
          .filter(id => this.suggestionDecisions[id] !== null)
          .map(id => ({ id, status: this.suggestionDecisions[id] }))

        if (data.length > 0) {
          return Suggestions.post(data)
        } else {
          return Promise.resolve()
        }
      },
      discardChanges () {
        // Add default values to missing properties
        const missingProps = emptyActor()

        for (const prop in missingProps) {
          if (missingProps.hasOwnProperty(prop)) {
            if (!(prop in this.originalActor) || this.originalActor[prop] === null) {
              this.originalActor[prop] = missingProps[prop]
            }
          }
        }

        // Save editable copy
        this.modifiedActor = inert(this.originalActor)

        // If we need to do translations, translate the keys we know need translation:
        // - taxonomies
        // - actor type
        if (this.isSimplifiedTemplateUsed) {
          ['membership', 'category', 'stage', 'funnel_stage'].forEach(property => {
            if (!this.originalActor[property] || !this.originalActor[property].value) {
              return
            }

            this.originalActor[property].label = this.getLabelForTaxonomyValue(property, this.originalActor[property].value)
          })

          this.modifiedActor = inert(this.originalActor)
        }
      },
      actorDiff () {
        return diffObjects(this.modifiedActor, this.originalActor)
      },
      contributorsDiff () {
        return !isEmpty(xor(this.actorContributors.filter(c => c.allow_conversation).map(c => c.value), this.originalActorContributors.filter(c => c.allow_conversation).map(c => c.value)))
      },
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      setActiveTabs (tab) {
        if (this.activeTabs === tab) {
          this.activeTabs = ''
        } else {
          this.activeTabs = tab
          setTimeout(() => {
            const container = this.$refs.container
            const tabOffset = this.$refs['tab_' + tab] ? this.$refs['tab_' + tab][0].offsetTop : 0
            const hudContainerHeight = this.$refs.hudContainer ? this.$refs.hudContainer.offsetHeight : 0
            const headerHeight = this.$refs.header ? this.$refs.header.offsetHeight : 0
            container.scrollTop = tabOffset - hudContainerHeight - headerHeight - 1
          }, 0)
        }
      },
      transformProductFeatures (productFeatures) {
        productFeatures.forEach((feature) => {
          if (feature.pivot != null && feature.remark != null) {
            feature.pivot.remark = feature.remark
          }
        })

        return productFeatures
      },
      fetch () {
        this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actorId)
      },
      async fetchActor () {
        return fetchActor(this.actorId)
      },
      showDeleteConfirmationModal (company) {
        const modalContextData = Object.assign(company, { modalContextType: 'actor' })

        this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      denyActorRecommendation (context) {
        if (!context.id || context.id !== this.originalActor.id) {
          return
        }

        removeActor(this.originalActor.id)
          .then(data => {
            if (!data.deleted) {
              throw new Error(data)
            }

            this.hidePreview()

            if (this.$route.path.startsWith('/actors/') && !this.$route.params.id) {
              this.$store.commit('ACTORS/FLUSH_CACHE')

              // Fetch the data after a couple of seconds, storing it in MySQL and indexing it in ElasticSearch shouldn't take up more time than that
              setTimeout(() => {
                const listFilters = inert(this.$store.getters.listFilterObject)
                this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

                const filters = this.$store.getters.mapFilterObject

                if (filters.tl) {
                  this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
                }
              }, 1000)
            } else if (this.$route.path.startsWith('/actors/') && this.$route.params.id && this.originalActor.id !== this.$route.params.id) {
              this.$store.commit('ACTORS/FLUSH_CACHE')

              this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
            } else if ((this.$route.path.startsWith('/actors/') || this.$route.path.startsWith('/communities/') || this.$route.path.startsWith('/spotting-areas/')) && this.$route.params.id) {
              // don't flush cache, otherwise the "actor: go to next" will not work as expected, instead fetch the actor details again
              // this.$store.commit('ACTORS/FLUSH_CACHE')

              this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)

              // emit confirmed actor, so that the "actor:go to next" automatically gets triggered
              this.$bus.emit('removeActorConfirmed')
            } else {
              this.$store.commit('ACTORS/FLUSH_CACHE')

              this.$router.push('/actors')
            }
          })
          .catch(errors => {
            console.warn('ActorEdit failed to delete actor', this.originalActor.id, errors)
          })
      },
      includeActorToPortfolio (portfolioData) {
        includeActorToPortfolio(portfolioData)
      },
      excludeActorFromPortfolio (portfolioData) {
        excludeActorFromPortfolio(portfolioData)
      },
      startAddingCategory (name) {
        this.show.addCategory = true
        this.newCategoryName = name

        setTimeout(() => {
          this.$refs.newCategoryNameInput.focus()
        }, 0)
      },
      addNewCategory () {
        const categoryList = this.categories[this.modifiedActor.actor_type]
        const name = this.newCategoryName
        const index = categoryList.indexOf(name)
        if (index < 0) {
          Categories
            .post({
              name,
              translations: {
                en: { name },
              },
              actor_type: this.modifiedActor.actor_type,
            })
            .catch(errors => {
              console.log('Failed to add category', errors)
            })

          this.$store.dispatch(TAXONOMY_ACTION_TYPES.REFRESH_ALL_TAXONOMIES)
          this.modifiedActor.category = this.newCategoryName
          this.show.addCategory = false
        } else {
          this.errors.category = 'Taxonomy item already exists'
        }
      },
      resetErrors () {
        this.errors = {}
      },
      onUpdateActorType () {
        this.modifiedActor.category = ''
        this.resetFieldList()
      },
      calculateTabCompleteness () {
        this.clearFields()
        this.calculateCompletionPerSection()
      },
      tabCompletenessPercentage (tabName) {
        for (const fieldName in this.fieldList) {
          if (this.fieldList.hasOwnProperty(fieldName)) {
            const field = this.fieldList[fieldName]
            if (field.name === tabName.toString().toLowerCase().replaceAll(' & ', '_').replaceAll(' ', '_')) {
              let percentage = Math.floor(field.count / (field.normalFields.length + field.specialFields.length) * 100)
              if (percentage > 100) percentage = 100
              return percentage + '%'
            }
          }
        }
      },
      fetchSuggestions () {
        if (!this.isOwner && !this.isTeamMember) {
          return
        }

        Suggestions.get('', { actor_id: this.actorId, limit: 100 })
          .then(data => {
            this.suggestions = data
            this.suggestionDecisions = this.getDecisionsForSuggestions(data)
          })
      },
      getDecisionsForSuggestions (suggestions) {
        const decisions = {}

        // Mark suggestions that already exist in the values as accepted
        for (const suggestion of suggestions) {
          decisions[suggestion.id] = null

          switch (suggestion.property) {
            case 'industries':
            case 'activities':
            case 'technology':
            case 'tags':
              if (this.originalActor[suggestion.property].includes(unArrayify(suggestion.value))) {
                decisions[suggestion.id] = 'accepted'
              }
              break
          }
        }

        // Mark all but one of multiple identical suggestions as denied
        const seenValues = []

        for (const suggestion of suggestions) {
          if (decisions[suggestion.id]) continue
          const value = unArrayify(suggestion.value)
          if (seenValues.includes(value)) {
            decisions[suggestion.id] = 'denied'
          } else {
            seenValues.push(value)
          }
        }

        return decisions
      },
      pendingSuggestionsForProperty (propName) {
        return this.suggestions.filter(s => s.property === propName && this.suggestionDecisions[s.id] === null)
      },
      findSuggestion (propName, value) {
        return this.suggestions.find(s => s.property === propName && (s.value === value || unArrayify(s.value) === value || unArrayify(s.value).id === value.value || (unArrayify(s.value) && unArrayify(s.value).name === value)))
      },
      decideSuggestion (property, value, decision) {
        const suggestion = this.findSuggestion(property, value)
        if (!suggestion) return
        this.suggestionDecisions[suggestion.id] = decision
        this.hasManualSuggestionDecisions = true
      },
      taxonomyValueForSuggestionValue (value, taxonomy) {
        const taxonomyValue = this[taxonomy].find(i => i.name === value.name || i.name === value)

        // The first and third option can probably be discard, normally only the second option should be the only valid one (value: value, label: value.name)
        if (taxonomyValue) {
          return taxonomyValue.id
        } else if (value.name !== undefined) {
          return { value: value.id, label: value.name }
        }

        return value
      },
      addIndustryToWindow (suggestedIndustries, industry) {
        this.$store.commit(TAXONOMY_MUTATION_TYPES.ADD_SUGGESTED_INDUSTRY, { suggestedIndustries, idToAdd: industry.id })
      },
      convertIndustryValuesToChanges (suggestedIndustries, values) {
        if (!values) return []

        const result = []

        for (const value of values) {
          if (typeof value === 'number') {
            result.push(value)
            continue
          } else if (value.value.id === undefined) {
            continue
          }

          this.addIndustryToWindow(suggestedIndustries, value.value)
          result.push(value.value.id)
        }

        return result
      },
      onDigitalPresenceDeleteAll (type) {
        switch (type) {
          case 'url':
          case 'linkedin':
          case 'instagram':
          case 'vimeo':
          case 'youtube':
          case 'kenniswest':
          case 'crunchbase':
            this.modifiedActor[type] = ''
            break
          default:
            this.modifiedActor[type] = []
            break
        }
      },
      transformIndustries (industriesData) {
        const industries = []

        industriesData.forEach((industry) => {
          if (!industries.some(e => e.id === industry.id)) {
            industries.push(industry)
          }
        })

        return industries
      },
    },
    async created () {
      this.$bus.on('actorDeleteConfirmation', this.denyActorRecommendation)
      this.$bus.on('cancelActorEditConfirmation', this.back)

      if (this.openTab) {
        this.activeTabs = this.openTab
      }

      // Always fetch the actor, make it so that this component is not depending on the actor detail that is kept in the state
      try {
        this.originalActor = await this.fetchActor()
        this.fetchContributors()
        this.discardChanges()
      } catch (e) {
        console.log('Something went wrong while fetching the actor: ' + e)
      }

      this.resetFieldList()

      if (this.modifiedActor.actor_type === 'Person' && !this.modifiedActor.employment) {
        this.modifiedActor.employment = {
          seniority: '',
          title: '',
          role: '',
        }
      }

      this.tabCompletenessInterval = setInterval(() => {
        this.calculateTabCompleteness()
      }, 1000)
      this.fetchSuggestions()
    },
    beforeUnmount () {
      this.$bus.off('actorDeleteConfirmation', this.denyActorRecommendation)
      this.$bus.off('cancelActorEditConfirmation', this.back)

      if (this.tabCompletenessInterval) {
        clearInterval(this.tabCompletenessInterval)
      }
    },
    watch: {
      originalActor () {
        this.discardChanges()
      },
      modifiedActor: {
        deep: true,
        handler () {
          // TODO: debounce if this is too heavy of an operation
          this.isEditing = this.actorDiff() || this.contributorsDiff()
        },
      },
      actorContributors: {
        deep: true,
        handler () {
          this.isEditing = this.actorDiff() || this.contributorsDiff()
        },
      },
    },
    mixins: [CompanyMixin, ConfigMixin, TagsMixin, TranslationsMixin],
    components: {
      TaxonomyQuickEdit,
      ActorEditDescription,
      ActorEditSkillsAndExpertise,
      ActorEditDigitalPresence,
      ActorEditActivities,
      ActorEditContactDetails,
      ActorEditAdministration,
      ActorEditFinancials,
      ActorEditProductServiceOffering,
      ActorEditSegmentation,
      Dropdown,
      DsInput,
      FormGroup,
      FormSection,
      ActionBar,
      ImageInput,
    },
  }
</script>
