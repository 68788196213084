import './init'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import FloatingVue from 'floating-vue'
import mitt from 'mitt'

import numeral from 'numeral'
import { createI18n } from '~/vue-i18n/dist/vue-i18n.esm-bundler.js'
import { loadLocaleMessages } from './i18n.js'
//import '@fortawesome/fontawesome-free/js/all.min.js'
import '~/vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueClickAway from 'vue3-click-away'
import Notifications from '@kyvg/vue3-notification'
import DsButton from './components/DsButton/DsButton.vue'
import DsInput from './components/Form/DsInput.vue'
import FormGroup from './components/Form/FormGroup.vue'
import Icon from './components/Icon/Icon.vue'
import GeneralError from './components/Errors/GeneralError.vue'
import Currency from './components/DataFormatters/Currency.vue'
import Date from './components/DataFormatters/Date.vue'
// import { DraggablePlugin } from '@braks/revue-draggable'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

// Semantic dropdown dependencies
import '~/semantic-ui-css/components/api.js'
import '~/semantic-ui-css/components/search.js'
import '~/semantic-ui-css/components/dropdown.js'
import '~/semantic-ui-css/components/transition.js'

import VueSocialSharing from 'vue-social-sharing'

import { asyncConfig } from './api/config.js'
import '../scss/main.scss'

import Collapsable from './components/CollapsablePanel/Collapsable.js'
import { AnalyticsMixin } from './util/analytics.js'
import './util/polyfill.js'
import { ConversationsMixin } from './util/ConversationsMixin.js'
import { NotificationsMixin } from './util/NotificationsMixin.js'
import UiMixin from './util/UiMixin.js'
import TranslationsMixin from './util/TranslationsMixin.js'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import UserbackPlugin from '~/@userback/vue'

const $bus = mitt()
FloatingVue.options.themes.tooltip.delay.show = 0

numeral.register('locale', 'datascouts-eur', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
    },
    ordinal: function (number) {
        const b = number % 10
        return (~~(number % 100 / 10) === 1) ? 'th'
            : (b === 1) ? 'st'
                : (b === 2) ? 'nd'
                    : (b === 3) ? 'rd' : 'th'
    },
    currency: {
        symbol: '€',
    },
})

numeral.register('locale', 'datascouts-usd', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
    },
    ordinal: function (number) {
        const b = number % 10
        return (~~(number % 100 / 10) === 1) ? 'th'
            : (b === 1) ? 'st'
                : (b === 2) ? 'nd'
                    : (b === 3) ? 'rd' : 'th'
    },
    currency: {
        symbol: '$',
    },
})

if (store.state.config && store.state.config.displayCurrency === 'usd') {
    numeral.locale('datascouts-usd')
} else {
    numeral.locale('datascouts-eur')
}

const i18 = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
})
/*const app = createApp({
  extends: App,
  mixins: [NotificationsMixin, asyncConfig, Collapsable, AnalyticsMixin, ConversationsMixin],
})*/

const app = createApp(App)

app.use(i18)

app.mixin([NotificationsMixin, asyncConfig, Collapsable, AnalyticsMixin, ConversationsMixin, UiMixin, TranslationsMixin])

app.config.globalProperties.$bus = $bus

app.provide('$store', store)
app.provide('$root', app)

app.use(Notifications)

app.use(store)
    .use(FloatingVue, {
        themes: {
            'info-tooltip': {
                $extend: 'tooltip',
            },
        },
    })
    //.use(DraggablePlugin)
    .use(router)

if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
    Raven
        .config(import.meta.env.VITE_SENTRY_DSN_PUBLIC)
        .addPlugin(RavenVue, app)
        .install()

    if (window.user) {
        Raven.setUserContext({
            id: window.user.id,
        })
    }
}

app.use(VueClickAway)
app.use(VueSocialSharing)

app.component('DsButton', DsButton)
app.component('DsInput', DsInput)
app.component('FormGroup', FormGroup)
app.component('GeneralError', GeneralError)
app.component('Icon', Icon)
app.component('Currency', Currency)
app.component('Date', Date)
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

/*app.use(VueNumerals, {
  locale: 'en'
})*/

if (import.meta.env.MODE !== 'development') {
    app.use(UserbackPlugin, {
        token: '10183|20260|ccgojlWqQmukJUR8dGQ7ov1s8q0ShqQS2xHaTlPPvSfnrdnRFl',
    })
}

app.provide('emitter', $bus)
    .mount('#app')

window.addEventListener('resize', () => {
    $bus.emit('resize')
})
