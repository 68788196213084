<template>
  <div class="simplified-hero"
       :class="heroClasses">
    <div class="simplified-hero__visual" :style="{backgroundImage: `url('${imgUrl}')`}">
      <div class="simplified-hero__actions">
        <slot name="actions"/>
      </div>
      <slot name="map" v-if="!imgUrl"/>
      <div class="actor-detail__top-right-btn-container" v-if="showNext">
        <ds-button class="actor-detail__next-btn"
                   style="border-color: #CECECE; right: -80px;" :label="$t('actor_detail_next')"
                   icon="double-chevron-right" @click="goToNext"/>
      </div>
    </div>
    <h1 class="simplified-hero__title" :style="bannerStyle || titleStyles"
        v-if="$slots.title || $slots.titleActions">
      <Container class="simplified-hero__title-container">
        <slot name="title"></slot>
        <div class="simplified-hero__title-actions">
          <slot name="titleActions"></slot>
        </div>
      </Container>
    </h1>
    <h2 v-if="$slots.subtitle" class="simplified-hero__subtitle"
        :style="subBannerStyle || subtitleStyles">
      <Container class="simplified-hero__subtitle-container">
        <slot name="subtitle"></slot>
      </Container>
    </h2>
  </div>
</template>

<script>
  import { darken } from 'polished'

  import Container from '../Container/Container.vue'
  import { trackHeapEvent } from '../../util/analytics.js'

  export default {
    name: 'SimplifiedHero',
    props: {
      imgUrl: String,
      hasSubtitle: {
        type: Boolean,
        default: false,
      },
      keepAspectRatio: {
        type: Boolean,
        default: false,
      },
      showNext: {
        type: Boolean,
        default: true,
      },
      bannerStyle: Object,
      subBannerStyle: Object,
    },
    components: {
      Container,
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      heroClasses () {
        if (!this.keepAspectRatio) {
          return this.hasSubtitle ? 'with-subtitle' : 'without-subtitle'
        } else {
          return this.hasSubtitle ? 'keep-AR-with-subtitle' : 'keep-AR-without-subtitle'
        }
      },
      config () {
        return this.$store.state.config
      },
      titleStyles () {
        return {
          backgroundColor: darken(0.25, this.config.primaryColor),
        }
      },
      subtitleStyles () {
        return {
          backgroundColor: this.config.primaryColor,
        }
      },
    },
    methods: {
      goToNext () {
        this.$emit('next')
      },
    },
  }
</script>

<style lang="scss">
  @import "resources/assets/scss/variables";

  .simplified-hero {
    display: flex;
    -webkit-flex: 1 0;
    flex: 1 0;
    flex-direction: column;
    justify-content: flex-end;
    // min-height: 235px;
    color: #fff;
    // margin-bottom: 3rem;

    &.with-subtitle {
      min-height: 278px;
    }

    &.without-subtitle {
      min-height: 235px;
    }

    $sidebarWidth: 40px;
    $titleHeight: 0px;
    $aspectRatio: calc(250/970);
    $subtitleHeight: 0px;

    &.keep-AR-with-subtitle {
      @media screen and (max-width: 48em) {
        $width: 100vw;
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$subtitleHeight} + #{$titleHeight});
        }
      }

      @media screen and (min-width: 48em) {
        $width: 736px; // 46 rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$subtitleHeight} + #{$titleHeight});
        }
      }

      @media screen and (min-width: 62em) {
        $width: 976px; // 61 rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$subtitleHeight} + #{$titleHeight});
        }
      }

      @media screen and (min-width: 75em) {
        $width: 1136px; // 71rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$subtitleHeight} + #{$titleHeight});
        }
      }
    }

    &.keep-AR-without-subtitle {
      @media screen and (max-width: 48em) {
        $width: 100vw;
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$titleHeight});
        }
      }

      @media screen and (min-width: 48em) {
        $width: 736px; // 46 rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$titleHeight});
        }

      }

      @media screen and (min-width: 62em) {
        $width: 976px; // 61 rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$titleHeight});
        }
      }

      @media screen and (min-width: 75em) {
        $width: 1136px; // 71rem
        .simplified-hero__visual {
          min-height: calc(#{$aspectRatio} * #{$width} + #{$titleHeight});
        }
      }
    }
  }

  .simplified-hero__visual {
    position: relative;
    flex: 1;
    background-size: cover;
    background-position: center;
  }

  .simplified-hero__actions {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    padding: 2rem;
    pointer-events: none;
  }

  .simplified-hero__title-container {
    display: flex;

  }

  .simplified-hero__title-actions {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
  }

  .simplified-hero__subtitle-container {
    display: flex;
  }

  .simplified-hero__title,
  .simplified-hero__subtitle {
    font-weight: normal;

    a {
      color: inherit;
    }
  }

  .simplified-hero__title {
    padding: 0.5rem 0;
    font-size: 1.5rem;
    line-height: calc(2rem + 8px);
    text-transform: uppercase;

    @media (max-width: $screen-md) {
      padding: 0.5rem 10px;
    }
  }

  .simplified-hero__subtitle {
    padding: 0.75rem 0;
    font-size: 1rem;

    @media (max-width: $screen-md) {
      padding: 0.75rem 10px;
    }
  }
</style>
